import { combineReducers } from "redux";
// import { reducer as form } from "redux-form";

import auth from "./auth_reducer";
import sudo from "./sudo_reducer";
import location from "./location_reducer";
import locations from "./locations_reducer";
import sensor from "./sensor_reducer";
import sensors from "./sensors_reducer";
import unregisteredSensors from "./unregistered_sensors_reducer";
import gateway from "./gateway_reducer";
import gateways from "./gateways_reducer";
import selected from "./selected_reducer";
import admins from "./admins_reducer";
import displayView from "./display_view_reducer";
import displays from "./displays_reducer";
import screen from "./screen_reducer";
import screens from "./screens_reducer";
import screenView from "./screen_view_reducer";
import feedback from "./feedback_reducer";
import locationTags from "./location_tags_reducer";
import sensorTags from "./sensor_tags_reducer";
import insights from "./insights_reducer";
import job from "./job_reducer";
import jobGroup from "./jobGroup_reducer";
import jobGroups from "./jobGroups_reducer";
import content from "./content_reducer";
import allContent from "./content_all_reducer";
import userGroup from "./user_group_reducer";
import userGroups from "./user_groups_reducer";
import user from "./user_reducer";
import users from "./users_reducer";
import supportUsers from "./support_users_reducer";
import invite from "./invite_reducer";
import invites from "./invites_reducer";
import dashboards from "./dashboards_reducer";
import customTag from "./custom_tag_reducer";
import customTags from "./custom_tags_reducer";
import filter from "./filter_reducer";
import filters from "./filters_reducer";
import offlineSensors from "./offline_sensors_reducer";
import offlineGateways from "./offline_gateways_reducer";
import uptime from "./uptime_reducer";
import occupancy from "./occupancy_metrics_reducer";
import appUsage from "./app_usage_reducer";
import loading from "./loading_reducer";
import report from "./report_reducer";
import serviceAccounts from "./serviceAccounts_reducer";
import serviceAccount from "./serviceAccount_reducer";
import graphResources from "./graphResources_reducer";
import accountSummary from "./account_summary_reducer";
import company from "./company_reducer";
import anomaly from "./anomaly_reducer";
import anomalies from "./anomalies_reducer";
import widgets from "./widgets_reducer";

export default combineReducers({
  loading,
  auth,
  sudo,
  location,
  locations,
  sensor,
  sensors,
  unregisteredSensors,
  gateway,
  gateways,
  selected,
  displayView,
  displays,
  screen,
  screens,
  screenView,
  feedback,
  locationTags,
  sensorTags,
  insights,
  job,
  jobGroup,
  jobGroups,
  content,
  allContent,
  userGroup,
  userGroups,
  user,
  users,
  invite,
  invites,
  admins,
  supportUsers,
  dashboards,
  customTag,
  customTags,
  filter,
  filters,
  offlineSensors,
  offlineGateways,
  uptime,
  occupancy,
  appUsage,
  report,
  serviceAccounts,
  serviceAccount,
  graphResources,
  accountSummary,
  company,
  anomaly,
  anomalies,
  widgets,
});
