import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Mapbox from "!mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import RotateMode from "mapbox-gl-draw-rotate-mode";
import SegmentedControl from "../../../components/SegmentedControl";
import { StateColors } from "../../../constants/colors";
import * as selectedActions from "../../../actions/selected";
import style from "./style.module.scss";
import { getMapBounds } from "../helpers";

let mapbox;
let shouldUpdateMap;
let mapHasLoaded;
let mapHasBeenCreated = false;
let mapHasBeenDeleted = false;
let hoveredStateId;
let loadSourceTimeout = null;

// Draw mode
let mapboxDraw;
let savedFeature;

// Pop up
let popup;

class MapboxMap extends Component {
  
  constructor(props) {
    // console.log("MapboxMap.constructor", props);
    super(props);
    shouldUpdateMap = false;
    mapHasLoaded = false;
    hoveredStateId = undefined;

    // Draw mode
    this.state = {
      drawMode: get(props, "drawMode", null),
      showMap: true
    };
    savedFeature = props.geoJsonFeature || {};

    this.onMapLoad = this.onMapLoad.bind(this);
    this.onMapRender = this.onMapRender.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onHoverLocation = this.onHoverLocation.bind(this);
    this.onHoverSensor = this.onHoverSensor.bind(this);
    this.onHoverGateway = this.onHoverGateway.bind(this);
    this.onModeChange = this.onModeChange.bind(this);
    this.onFeaturesSelected = this.onFeaturesSelected.bind(this);
    this.addLocationGeoJson = this.addLocationGeoJson.bind(this);
    this.fixLocationFeatures = this.fixLocationFeatures.bind(this);
    this.fixSensorFeatures = this.fixSensorFeatures.bind(this);
    this.fixGatewayFeatures = this.fixGatewayFeatures.bind(this);
    this.redrawFeatures = this.redrawFeatures.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  loadConfig() {
    mapHasBeenCreated = true;

    // Build config
    const config = {
      container: this.props.mapId,
      autoSetRTLTextPlugin: false
    };

    if (get(this.props.location, "geoJsonFeature.geometry", null)) {
      // Center for mapped location
      const llb = getMapBounds([this.props.location.geoJsonFeature]);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50 };
    }
    else if (get(this.props.map, "features[0].geometry", null)) {

      // If region - zoom to children
      const children = get(this.props.location, "children", []);
      const childFeatures = this.props.map.features.filter(feat => children.includes(get(feat, "properties.locationId", "")));
      
      if (get(this.props.location, "type", "") === "region" && childFeatures.length > 0) {
        // Center for children of a region location
        const llb = getMapBounds(childFeatures);
        config.bounds = llb.toArray();
        config.fitBoundsOptions = { padding: 50 };
      }
      else {
        // Center for unmapped location
        const llb = getMapBounds(this.props.map.features);
        config.bounds = llb.toArray();
        config.fitBoundsOptions = { padding: 50 };
      }
    }
    else {
      // Skandinavia
      config.center = { lng: 17.7099499, lat: 64.55875 };
      config.zoom = 3.5;
    }

    Mapbox.accessToken = "pk.eyJ1IjoidG5zbWFydHgiLCJhIjoiY2s4aXJ6MTYxMDQycjNsbjYxZTA2ZmY5diJ9.6cYHg6iYWpNrtSuAjf-Eyg";
    config.style = "mapbox://styles/mapbox/streets-v11";
    config.attributionControl = false;

    mapbox = new Mapbox.Map(config).addControl(new Mapbox.AttributionControl({
      compact: true
    }));

    popup = new Mazemap.Popup({ // eslint-disable-line no-undef
      closeButton: false,
      closeOnClick: false
    });

    // Mark map as created
    mapHasBeenDeleted = false;

    mapbox.on('load', this.onMapLoad);
    mapbox.on('click', this.onMapClick);
    mapbox.on("mousemove", "location-fill", this.onHoverLocation);
    mapbox.on("mouseleave", "location-fill", this.onDehover);
    mapbox.on("mousemove", "unclustered-sensors", this.onHoverSensor);
    mapbox.on("mouseleave", "unclustered-sensors", this.onDehover);
    mapbox.on("mousemove", "unclustered-gateways", this.onHoverGateway);
    mapbox.on("mouseleave", "unclustered-gateways", this.onDehover);

    if (this.state.drawMode) {
      const mazeMapOptions = {
        userProperties: true,
        displayControlsDefault: false,
        defaultMode: this.state.drawMode,
        modes: {...MapboxDraw.modes, RotateMode },
        styles: [
          {
            "id": "gl-draw-point-stroke-active",
            "type": "circle",
            "filter": ["all", ["==", "$type", "Point"],
              ["==", "active", "true"],
              ["!=", "meta", "midpoint"]
            ],
            "paint": {
              "circle-radius": 5,
              "circle-color": "#000000"
            }
          },
          {
            "id": "gl-draw-point-active",
            "type": "circle",
            "filter": ["all", ["==", "$type", "Point"],
              ["!=", "meta", "midpoint"],
              ["==", "active", "true"]
            ],
            "paint": {
              "circle-radius": 4,
              "circle-color": this.props.drawSensor ? "#8FE7FF" : "#FFA95E"
            }
          }
        ]
      }

      mapboxDraw = new MapboxDraw(mazeMapOptions);
      mapbox.addControl(mapboxDraw, "top-left");
      
      mapbox.on("draw.create", event => {
        console.log("draw.create", event);
        const feature = get(event, "features[0]", null);
        if (feature) {
          savedFeature = feature;
          this.props.addCreatedFeature(savedFeature);
        }
      });

      mapbox.on("draw.update", event => {
        console.log("draw.update", event);
        const featureCollection = mapboxDraw.getAll();
        console.log("draw.update.featureCollection", featureCollection);
        const createdFeature = featureCollection.features.find(feature => (feature.id === savedFeature.id));
        savedFeature = createdFeature;
        this.props.addCreatedFeature(savedFeature);
      });

      mapbox.on("draw.render", this.onMapRender);

      mapbox.on("draw.selectionchange", event => {
        // console.log("draw.selectionchange", event);

        // Re-select sensor/gateway if user try to select something else
        const featureId = get(event, "features[0].id")
        // console.log("draw featureId", featureId);
        // console.log("draw  savedFeature.id",  savedFeature.id);
        if (featureId !== savedFeature.id) {
          mapboxDraw.changeMode("simple_select", { featureIds: [savedFeature.id] });
        }
      });

      
    }

    const loadSource = () => {
      // console.log("loadConfig.loadSource date", new Date().toISOString());
      // console.log("loadConfig.loadSource isStyleLoaded =", mapbox.isStyleLoaded());
      // console.log("loadConfig.loadSource mapHasLoaded =", mapHasLoaded);
      // console.log("loadConfig.loadSource Loaded =", mapbox.loaded());
      
      if (mapbox.loaded()) {
        if (mapHasLoaded) {
          this.redrawFeatures();
        }
        else {
          mapHasLoaded = true;

          // console.log("loadConfig.loadSource Loaded 1");
  
          // Add geometry
          if (get(this.props.map, "features[0]", false)) {
            // console.log("loadConfig.loadSource Loaded 2");
            this.addLocationGeoJson();
            if (mapboxDraw && this.props.geoJsonFeature) {
              this.drawFeatures(this.props.geoJsonFeature);
            }
          }
        }
      }
      else {
        clearTimeout(loadSourceTimeout);
        loadSourceTimeout = setTimeout(loadSource, 200);
      }
    };
    
    clearTimeout(loadSourceTimeout);
    loadSourceTimeout = setTimeout(loadSource, 200);
  }

  componentDidMount() {
    // console.log("MapboxMap.componentDidMount", this.props);
    if (this.props.showMap) {
      this.loadConfig();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("MapboxMap.shouldComponentUpdate", nextProps, nextState);
    shouldUpdateMap = true;
    return true;
  }
  
  componentDidUpdate() {
    // console.log("MapboxMap.componentDidUpdate", this.props);
    if (mapHasBeenCreated) {
      if (shouldUpdateMap) {
        shouldUpdateMap = false;
        this.onMapLoad();
      }
    }
    else if (this.props.showMap) {
      this.loadConfig();
    }
  }

  componentWillUnmount() {
    // console.log("MapboxMap.componentWillUnmount");
    clearTimeout(loadSourceTimeout);
    if (mapbox && !mapHasBeenDeleted) {
      mapHasBeenDeleted = true;
      mapbox.remove();
    }
  }

  // Show map after first render
  onMapRender(event) {
    if (mapHasLoaded && !this.state.showMap) {
      this.setState({ showMap: true });
    }
  }

  onMapLoad() {
    // console.log("onMapLoad");
    if (mapHasLoaded) {
      this.redrawFeatures();
      mapbox.resize();
    }
  }

  onModeChange(value) {
    // console.log("onModeChange", value);
    this.props.changeConfigMode(value);
  }

  redrawFeatures() {
    // console.log("redrawFeatures");

    // Add colors to source
    if (get(this.props.map, "features", false)) {
      
      // Add all features to map
      const locationFeatures = this.fixLocationFeatures(this.props.map.features);
      const locationsSource = mapbox.getSource('locations');
      if (locationsSource) {
        locationsSource.setData({ type: "FeatureCollection", features: locationFeatures });
      }

      if (this.props.showSensors) {
        const sensorFeatures = this.fixSensorFeatures(this.props.map.features);
        const sensorsSource = mapbox.getSource('sensors');
        if (sensorsSource) {
          sensorsSource.setData({ type: "FeatureCollection", features: sensorFeatures });
        }
      }
      else {
        const sensorsSource = mapbox.getSource('sensors');
        if (sensorsSource) {
          sensorsSource.setData({ type: "FeatureCollection", features: [] });
        }
      }

      if (this.props.showGateways) {
        const gatewayFeatures = this.fixGatewayFeatures(this.props.map.features);
        const gatewaysSource = mapbox.getSource('gateways');
        if (gatewaysSource) {
          gatewaysSource.setData({ type: "FeatureCollection", features: gatewayFeatures });
        }
      }
      else {
        const gatewaysSource = mapbox.getSource('gateways');
        if (gatewaysSource) {
          gatewaysSource.setData({ type: "FeatureCollection", features: [] });
        }
      }
    }

    // Redraw
    if (mapboxDraw) {
      // console.log("this.props.geoJsonFeature X", this.props.geoJsonFeature);
      // mapboxDraw.deleteAll();
      // this.drawFeatures(this.props.geoJsonFeature);
    }
  }

  drawFeatures(focusedFeature) {
    // console.log("FancyMap.drawFeatures()", focusedFeature, mapboxDraw);
    // Add sensor or gateway
    if (!isEmpty(focusedFeature)) {
      // console.log("focusedFeature", focusedFeature, mapboxDraw);
      
      mapboxDraw.add({ ...focusedFeature, properties: {}});  // { sensor: true } - will show sensor color
      mapboxDraw.changeMode("simple_select", { featureIds: [focusedFeature.id] });
      // mapboxDraw.select(focusedFeature.id);
      // console.log(mapbox.getStyle().layers);
    }
  }

  onHoverLocation(event) {
    if (event.features.length > 0) {
      // console.log("onHoverLocation", event.features[0].state, this.props.configMode);
      if (hoveredStateId !== undefined) {
        mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
      }

      // Register changes in hovered feature - to show in location list
      const feature = get(event, "features[0]", null);
      if (hoveredStateId !== feature.id) {
        if (feature) {
          this.props.setHoveredFeature(feature);
        }
        else {
          this.props.setHoveredFeature(null);
        }
      }

      hoveredStateId = feature.id;

      if (this.props.showConnectForNewLocation) {
        // console.log("feature", feature);
        if (feature.properties.locationId) {
          mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverNoAction: false });
        }
        else {
          mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverConnect: true });
        }
      }
      else if (this.props.onGotoLocation) {
        // hoveredStateId = event.features[0].id;
        if (this.props.configMode === "connect") {
          mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverConnect: true });
        }
        else  {
          const locationId = get(event, "features[0].properties.locationId", null);
          if (locationId && locationId !== this.props.location.id) {
            mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverGoto: true });
          }
        }
      }
    }
  }

  onHoverSensor(event) {
    if (event.features.length > 0) {
      if (hoveredStateId !== undefined) {
        mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
      }

      const feature = get(event, "features[0]", null);
      const sensor = JSON.parse(get(feature, "properties.sensor", "{}"));

      var coordinates = feature.geometry.coordinates.slice();
      popup
        .setLngLat(coordinates)
        .setHTML(`<span>${sensor.name}</span>`)
        .addTo(mapbox);

      // Register changes in hovered feature - to show in sensor list
      if (hoveredStateId !== feature.id) {
        if (feature) {
          this.props.setHoveredFeature(feature);
        }
        else {
          this.props.setHoveredFeature(null);
        }
      }

      hoveredStateId = feature.id;
      
      if (this.props.onGotoSensor) {
        if (this.props.configMode === "connect") {
          mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverConnect: true });
        }
        else {
          mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverGoto: true });
        }
      }
    }
  }

  onHoverGateway(event) {
    if (event.features.length > 0) {
      if (hoveredStateId !== undefined) {
        mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
        mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
      }

      // Register changes in hovered feature - to show in gateway list
      const feature = get(event, "features[0]", null);
      const gateway = JSON.parse(get(feature, "properties.gateway", "{}"));

      var coordinates = feature.geometry.coordinates.slice();
      popup
        .setLngLat(coordinates)
        .setHTML(`<span>${gateway.name}</span>`)
        .addTo(mapbox);
      
      if (hoveredStateId !== feature.id) {
        if (feature) {
          this.props.setHoveredFeature(feature);
        }
        else {
          this.props.setHoveredFeature(null);
        }
      }

      hoveredStateId = feature.id;
      
      if (this.props.onGotoGateway) {
        if (this.props.configMode === "connect") {
          mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverConnect: true });
        }
        else {
          mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverGoto: true });
        }
      }
    }
  }

  onDehover() {
    if (popup) {
      popup.remove();
    }

    if (hoveredStateId !== undefined) {
      mapbox.setFeatureState({ source: 'locations', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
      mapbox.setFeatureState({ source: 'sensors', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
      mapbox.setFeatureState({ source: 'gateways', id: hoveredStateId }, { hoverGoto: false, hoverConnect: false });
    }
    hoveredStateId = undefined;
  }

  onMapClick(event) {
    // console.log("onMapClick", event);
    // console.log("LAYERS: ", mapbox.getStyle().layers);

    // Select sensor
    const unclusteredSensorsLayer = mapbox.getLayer('unclustered-sensors');
    if (typeof unclusteredSensorsLayer !== "undefined") {

      const sensors = mapbox.queryRenderedFeatures(event.point, { layers: ['unclustered-sensors'] });
      if (sensors.length > 0) {

        const sensorString = sensors[0].properties.sensor;
        const selectedSensor = JSON.parse(sensorString);

        if (this.props.configMode === "connect") {
          const selectedFeature = this.props.map.features.find(feature => (feature.id === selectedSensor._id));
          this.onFeaturesSelected([selectedFeature]);
        }
        else if (selectedSensor._id && this.props.onGotoSensor) {
            this.props.onGotoSensor(selectedSensor._id);
          }

        return;
      }
    }
    
    // Select gateway
    const unclusteredGatewaysLayer = mapbox.getLayer('unclustered-gateways');
    if (typeof unclusteredGatewaysLayer !== "undefined") {

      const gateways = mapbox.queryRenderedFeatures(event.point, { layers: ['unclustered-gateways'] });
      if (gateways.length > 0) {

        const gatewayString = gateways[0].properties.gateway;
        const selectedGateway = JSON.parse(gatewayString);
        
        if (this.props.configMode === "connect") {
          const selectedFeature = this.props.map.features.find(feature => (feature.id === selectedGateway._id));
          this.onFeaturesSelected([selectedFeature]);
        }
        else if (selectedGateway._id && this.props.onGotoGateway) {
            this.props.onGotoGateway(selectedGateway._id);
          }

        return;
      }
    }
       
    // Select location
    const locationLayer = mapbox.getLayer('location-fill');
    if (typeof locationLayer !== "undefined") {
      const features = mapbox.queryRenderedFeatures(event.point, { layers: ['location-fill'] });

      // Select top feature
      const topFeature = features[0];
      if (topFeature) {
        const selectedFeature = this.props.map.features.find(feature => (feature.id === topFeature.properties.id));
        if (selectedFeature) {
          if (this.props.showConnectForNewLocation) {
            this.onFeaturesSelected([selectedFeature]);
          }
          else if (this.props.configMode === "connect") {
            this.onFeaturesSelected([selectedFeature]);
          }
          else {
            // console.log(selectedFeature);
            const locationId = get(selectedFeature, "properties.locationId", null);
            if (locationId && this.props.onGotoLocation) {
              this.props.onGotoLocation(locationId);
            }
          }
        }
        else {
          this.onFeaturesSelected([]);
        }
      }
      else {
        this.onFeaturesSelected([]);
      }
    }
  }

  onFeaturesSelected(features, mode) {

    if (this.props.selectedMapFeatures.length !== features.length) {
      this.props.setSelectedFeatures(features, mode);
    }
    else {

      // Check for changes
      let isDifferent = false;
      this.props.selectedMapFeatures.forEach(selectedMapFeature => {
        features.forEach(feature => {
          if (selectedMapFeature.id === feature.id) {
            isDifferent = true;
          }
        })
      });

      if (isDifferent) {
        this.props.setSelectedFeatures(features, mode);
      }
    }
  }

  addLocationGeoJson() {

    // Add colors to source
    const locationSource = this.fixLocationFeatures(this.props.map.features);
    const sensorSource = this.fixSensorFeatures(this.props.map.features);
    const gatewaySource = this.fixGatewayFeatures(this.props.map.features);

    mapbox.addSource("locations", {
      type: "geojson",
      data: { type: "FeatureCollection", features: locationSource },
      generateId: true
    });

    mapbox.addSource("sensors", {
      type: "geojson",
      data: { type: "FeatureCollection", features: sensorSource },
      generateId: true,
      // cluster: true,
      // clusterMaxZoom: 20, // Max zoom to cluster points on
      // clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
    });

    mapbox.addSource("gateways", {
      type: "geojson",
      data: { type: "FeatureCollection", features: gatewaySource },
      generateId: true,
      // cluster: true,
      // clusterMaxZoom: 20, // Max zoom to cluster points on
      // clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
    });

    mapbox.addLayer({
      id: "location-fill",
      type: "fill",
      source: "locations",
      layout: {},
      paint: {
        "fill-color": ["case",
          ["boolean", ["feature-state", "hoverGoto"], false],
          "#1C4D82",
          ["case",
            ["boolean", ["feature-state", "hoverConnect"], false],
            "limegreen",
            ["get", "color"],
          ],
        ],
        "fill-opacity": ["get", "opacity"]
      }
    });

    mapbox.addLayer({
      id: "location-line",
      type: "line",
      source: "locations",
      layout: {},
      paint: {
        "line-color": "#777",
        "line-opacity": ["get", "opacity"]
      }
    });

    mapbox.addLayer({
      id: "unclustered-sensors",
      type: "circle",
      source: "sensors",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": ["case",
          ["boolean", ["feature-state", "hoverGoto"], false],
          "#1C4D82",
          ["get", "color"]
        ],
        "circle-radius": 4,
        "circle-stroke-width": 1,
        "circle-stroke-color": [
          "case", 
          ["has", "stroke-color"], ["get", "stroke-color"],
          "#777"
        ]
      }
    });

    mapbox.addLayer({
      id: "unclustered-gateways",
      type: "circle",
      source: "gateways",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": ["case",
          ["boolean", ["feature-state", "hoverGoto"], false],
          "#1C4D82",
          ["get", "color"]
        ],
        "circle-radius": 4,
        "circle-stroke-width": 1,
        "circle-stroke-color": [
          "case", 
          ["has", "stroke-color"], ["get", "stroke-color"],
          "#777"
        ]
      }
    });

    // console.log("LAYERS: ", mapbox.getStyle().layers);

    // Move draw layers to top
    try {
      if (this.state.drawMode) {
        mapbox.moveLayer("gl-draw-point-stroke-active.cold");
        mapbox.moveLayer("gl-draw-point-active.cold");
        mapbox.moveLayer("gl-draw-point-stroke-active.hot");
        mapbox.moveLayer("gl-draw-point-active.hot");
      }
    }
    catch (err) {
      console.log("Error moving draw layers to top: ", err);
    }
  }

  fixLocationFeatures(features) {
    const locationFeatures = features.filter(feature => (feature.properties.sensor === undefined && feature.properties.gateway === undefined));
    return locationFeatures.map(feature => {
      if (feature.properties) {

        // Highlighted mapped location
        if (this.props.highlightedId && feature.properties.locationId === this.props.highlightedId) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", opacity: 0.5 } };
        }
        
        // Selected feature
        if (this.props.selectedFeatures.find(selectedFeature => (selectedFeature.id === feature.id))) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "limegreen", opacity: 0.5 } };
        }
         
        // Selected location
        if (this.props.selectedLocations[feature.properties.locationId]) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", opacity: 0.5 } };
        }

        // Render floor
        if (feature.properties.locationId === feature.properties.floorId) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#ccc", opacity: 1.0 } };
        }
        
        // Render locations on building level
        if ( this.props.location.type === "building") {
          
          // Current mapped location
          if (feature.properties.locationId === this.props.location.id) {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#999", opacity: 1.0 } };
          }
          
          // Mapped locations
          if (feature.properties.locationId) {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#ccc", opacity: 1.0 } };
          }
        }

        // Current mapped location
        if (feature.properties.locationId === this.props.location.id) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#999", opacity: 0.5 } };
        }
        
        // Mapped locations
        if (feature.properties.locationId) {
          return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#ccc", opacity: 0.5 } };
        }
        
        // Unmapped locations
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#eee", opacity: 0.5 } };
      }
      
      // Unmapped locations
      return { ...feature, properties: { id: feature.id, color: "#eee", opacity: 0 } };
    });
  }

  fixSensorFeatures(features) {
    if (!this.props.showSensors) {
      return [];
    }
    const sensorFeatures = features.filter(feature => (feature.properties.sensor !== undefined));
    return sensorFeatures.map(feature => {

      if (this.props.geoJsonFeature && this.props.geoJsonFeature.id === feature.id) {
        return {};
      }

      // Sensor
      const foundSelectedSensorId = Object.keys(this.props.selectedSensors).find(selectedSensorId => {
        return feature.properties.sensor._id === selectedSensorId;
      });

      // Selected sensor
      if (foundSelectedSensorId) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } };
      }
      
      // Highlighted mapped sensor
      if (feature.properties.sensor._id === this.props.highlightedId) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } }; // 1C4D82 // 8B11E5 - 690CAD
      }

      // If placeing new sensor/gateway (drawing mode) - draw all sensors blue
      if (this.state.drawMode) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#0080FF", "stroke-color": "#0059B3", opacity: 1.0 } };
      }

      // Sensor is a direct child of this location
      if (feature.properties.sensor.locations.find(locationId => locationId === this.props.id)) {

        if (!isEmpty(this.props.sensors)) {
          const sensor = this.props.sensors.find(s => s.id === feature.properties.sensor._id);
          const connectionState = get(sensor, "properties.metadata.connectionState", "unknown");
  
          if (connectionState === "disconnected") {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disconnected, "stroke-color": "#111", opacity: 1.0 } };
          }
  
          if (connectionState === "connected") {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.connected, "stroke-color": "#111", opacity: 1.0 } };
          }
        }
        
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disabled, "stroke-color": "#111", opacity: 1.0 } };
        // return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } };
      }

      // Show status for the sensor that is not in this location as well
      const connectionState = get(feature, "properties.sensor.connectionState", "unknown");
      if (connectionState === "disconnected") {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disconnected, "stroke-color": "#777", opacity: 1.0 } };
      }

      if (connectionState === "connected") {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.connected, "stroke-color": "#777", opacity: 1.0 } };
      }
      
      return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disabled, "stroke-color": "#777", opacity: 1.0 } };
    });
  }

  fixGatewayFeatures(features) {
    if (!this.props.showGateways) {
      return [];
    }
    const gatewayFeatures = features.filter(feature => (feature.properties.gateway !== undefined));
    return gatewayFeatures.map(feature => {

      // console.log("gateway:", feature);
      // Do not draw selected gateway here - draw with mapboxDraw - that way we can move it
      if (this.props.geoJsonFeature && this.props.geoJsonFeature.id === feature.id) {
        return {};
      }
      
      // Gateway
      const foundSelectedGatewayId = Object.keys(this.props.selectedGateways).find(selectedGatewayId => {
        return feature.properties.gateway._id === selectedGatewayId;
      });

      // Selected gateway
      if (foundSelectedGatewayId) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } };
      }
      
      // Highlighted mapped gateway
      if (feature.properties.gateway._id === this.props.highlightedId) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } };
      }

      // If placeing new sensor/gateway (drawing mode) - draw all gateways brown
      if (this.state.drawMode) {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#82612F", "stroke-color": "#36260E", opacity: 1.0 } };
      }

      // Gateway is a direct child of this location
      if (feature.properties.gateway.locations.find(locationId => locationId === this.props.id)) {

        if (!isEmpty(this.props.gateways)) {
          const gateway = this.props.gateways.find(g => g.id === feature.properties.gateway._id);
          const connectionState = get(gateway, "properties.connectionState", "unknown");

          if (connectionState === "disconnected") {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disconnected, "stroke-color": "#777", opacity: 1.0 } };
          }
  
          if (connectionState === "connected") {
            return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.connected, "stroke-color": "#777", opacity: 1.0 } };
          }
        }

        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disabled, "stroke-color": "#777", opacity: 1.0 } };
        // return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#1C4D82", "stroke-color": "#005C9C", opacity: 1.0 } };
      }

      // Show status for the sensor that is not in this location as well
      const connectionState = get(feature, "properties.gateway.connectionState", "unknown");
      if (connectionState === "disconnected") {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disconnected, "stroke-color": "#777", opacity: 1.0 } };
      }

      if (connectionState === "connected") {
        return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.connected, "stroke-color": "#777", opacity: 1.0 } };
      }
      
      return { ...feature, properties: { ...feature.properties, id: feature.id, color: StateColors.disabled, "stroke-color": "#777", opacity: 1.0 } };
      
      // return { ...feature, properties: { ...feature.properties, id: feature.id, color: "#eee", opacity: 1.0 } };
    });
  }

  render() {
    // console.log("MapboxMap.render()", this.props);
    return (
      <div className={style.mapContainer}>
        <div id={this.props.mapId} className={style.map} style={{ opacity: this.state.showMap ? 1 : 0 }} />
        <div className={style.modeButton}>
          { this.props.showConnectMode && (
            <SegmentedControl 
              name="mode" 
              value={this.props.configMode} 
              onChange={this.onModeChange} 
              options={[ 
                { label: "Navigate", value: "goto", default: true },
                { label: "Connect", value: "connect", color: "green" }
              ]} 
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clickedMapFeature: state.selected.mapFeature,
    selectedFeatures: state.selected.features,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    mapMode: state.selected.mapMode,
    configMode: state.selected.configMode,
    selectedMapFeatures: state.selected.features
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectMapFeature: selectedActions.selectMapFeature,
    deselectMapFeature: selectedActions.deselectMapFeature,
    setSelectedFeatures: selectedActions.setSelectedFeatures,
    changeConfigMode: selectedActions.changeConfigMode,
    setHoveredFeature: selectedActions.setHoveredFeature,
    addCreatedFeature: selectedActions.addCreatedFeature,
    storeEditedFeatures: selectedActions.storeEditedFeatures,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MapboxMap);