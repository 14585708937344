import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import get from "lodash/get";
import { compressToEncodedURIComponent } from "lz-string";
import { Popup, PopupAnchor, SourceAnchor } from "ui";
import Widget from "../Widget/widget";
import * as widgetActions from "../../../actions/widgets";
import * as reportActions from "../../../actions/reports";
import styled from "styled-components";

class WidgetGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoverOverPoint: null,
      hoverOverTooltipText: null,
      isMenuVisible: false
    };

    this.optionButtonRef = React.createRef();
    this.menuRef = React.createRef();

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.handleScrollInMenu = this.handleScrollInMenu.bind(this);
    this.onSingleItemClick = this.onSingleItemClick.bind(this);
    this.onReportClick = this.onReportClick.bind(this);
    this.addWidgetToOverview = this.addWidgetToOverview.bind(this);
  }

  showMenu() {
    this.setState((prevState) => ({ isMenuVisible: !prevState.isMenuVisible }));
  }

  hideMenu(event) {
    if (this.menuRef.current && !this.menuRef.current.contains(v.target)) {
      this.setState({ isMenuVisible: false });
    }
  }

  handleScrollInMenu() {
    this.setState({ isMenuVisible: false }); // Hide the popup on scroll
  }

  onSingleItemClick(event, item) {
    // console.log("item", item)
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${item.locationId}`);
  }

  onReportClick(locationId, workplaceCategoryId) {
    const filters = [{
      lid: locationId,
      wpc: workplaceCategoryId
    }];

    // Default date range is last 30 days (minus last 2 days)
    const defaultStart = moment().subtract(1, "month").startOf("isoWeek");
    const lastAvailableDate = moment().subtract(2, "days").startOf("day");

    let defaultEnd = moment();
    if (defaultEnd.isAfter(lastAvailableDate)) {
      defaultEnd = lastAvailableDate.startOf("day");
    }

    const dateRanges = [{ startDate: defaultStart.toISOString(), endDate: defaultEnd.toISOString() }];
    this.props.setWorkplaceQuery(dateRanges, false, false, locationId, workplaceCategoryId, null);

    this.props.history.push(`/companies/${this.props.match.params.companyId}/reports?f=${compressToEncodedURIComponent(JSON.stringify(filters))}`);
  }

  addWidgetToOverview(widget) {
    const overviewWidgets = [...this.props.widgetProfile?.overviewWidgets];
    const widgetIndex = overviewWidgets.findIndex(w => w.source === widget.id && w.wpaCategoryId === widget.wpaCategoryId && w.customTagId === widget.customTagId);

    if (widgetIndex === -1) {
      const widgetBlueprint = {
        type: widget.type,
        source: widget.id
      };

      if (widget.wpaCategoryId) {
        widgetBlueprint.wpaCategoryId = widget.wpaCategoryId;
      }

      if (widget.customTagId) {
        widgetBlueprint.customTagId = widget.customTagId;
      }

      widgetBlueprint.locationIds = [this.props.building.id];

      overviewWidgets.push(widgetBlueprint);
    }
    else {
      // Check if the locationId is already in the widget
      const locationIndex = overviewWidgets[widgetIndex].locationIds.findIndex(lid => lid === this.props.building.id);
      if (locationIndex === -1) {
        overviewWidgets[widgetIndex].locationIds.push(this.props.building.id);
      }
      else {
        overviewWidgets[widgetIndex].locationIds.splice(locationIndex, 1);
      }

      // Remove the widget if there are no locationIds left
      if (overviewWidgets[widgetIndex].locationIds.length === 0) {
        overviewWidgets.splice(widgetIndex, 1);
      }
    }

    console.log("overviewWidgets", overviewWidgets);
    const newWidgetProfile = { hiddenWidgets: [], ...this.props.widgetProfile, overviewWidgets };
    this.props.setWidgetProfile(this.props.match.params.companyId, newWidgetProfile);
  }

  render() {
    // const { isLoading } = this.props;
    // console.log("Home.WidgetGrid.state", this.state);
    // console.log("Home.WidgetGrid.props", this.props);

    // if (isLoading) {
    //   return <Loader fullScreen />;
    // }

    if (!this.props.building) {
      return null;
    }

    // Show tooltip if hovering over a point with a tooltip
    let tooltip = null;
    if (this.state.hoverOverPoint && this.state.hoverOverTooltipText) {
      tooltip = (
        <Popup
          isVisible={true}
          sourceRect={this.state.hoverOverPoint}
          sourceAnchor={SourceAnchor.CENTER}
          popupAnchor={PopupAnchor.BOTTOM_CENTER}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            borderWidth: "1px",
            borderColor: "#ccc",
            borderStyle: "solid",
            zIndex: 1000,
            boxShadow: "2px 2px 5px 0px #0004"
          }}
        >
          <div style={{ padding: "10px", fontSize: "14px", lineHeight: "20px" }}>
            {this.state.hoverOverTooltipText}
          </div>
        </Popup>
      );
    }

    // Build widgets
    const widgets = this.props.widgets;
    const content = (
      <>
        {tooltip}
        <Tableau>
          {
            widgets.map((widget, index) => {

              const selectedBuildingId = this.props.building.id;
              let selectedWpaCategoryId = this.props.page.wpaCategoryId;
              let selectedCustomTagId = this.props.customTagId;

              if (this.props.page.id === "overview") {
                // Use data source ids from the widget, in overview, if it has any
                const selectedWpaCategory = this.props.wpaCategories.find(wpaCategory => wpaCategory._id === widget.wpaCategoryId);
                selectedWpaCategoryId = selectedWpaCategory?._id;
                selectedCustomTagId = widget.customTagId;

              }
              else if (!selectedWpaCategoryId) {
                // Use data source ids from the widget, in overview, if it has any
                selectedCustomTagId = widget.customTagId;
              }

              const widgetKey = `${selectedBuildingId}-${selectedWpaCategoryId ?? null}-${selectedCustomTagId ?? null}`; // use null instead of undefined
              const sourceData = get(this.props.widgetData, `[${widgetKey}].${widget.id}`, []);
              const loading = get(this.props.widgetLoading, `[${widgetKey}].${widget.id}`, false);

              // Check if the widget is already in the overview
              const widgetIsInOverview = !!this.props.widgetProfile?.overviewWidgets?.find(w => w.source === widget.id && w.wpaCategoryId === selectedWpaCategoryId && w.customTagId === selectedCustomTagId && w.locationIds?.includes(selectedBuildingId));

              return (
                <Widget
                  key={index}
                  widget={widget}
                  sourceData={sourceData}
                  loading={loading}
                  building={this.props.building}
                  page={this.props.page}
                  onSingleItemClick={this.onSingleItemClick}
                  showLocations={this.props.onShowSourcesForWidget}
                  showDataQuality={this.props.onShowDataQualityForWidget}
                  addWidgetToOverview={!widgetIsInOverview && this.addWidgetToOverview}
                  removeWidgetFromOverview={widgetIsInOverview && this.addWidgetToOverview}
                />
              );
            })
          }
        </Tableau>
      </>
    );

    return content;
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.customTags.list,
    widgetData: state.widgets.data,
    widgetLoading: state.widgets.loading,
    wpaCategories: state.report.wpaCategories,
    widgetProfile: state.widgets.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getWpaCategories: reportActions.getWpaCategories,
    setWidgetProfile: widgetActions.setWidgetProfile,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetGrid);

// Independent columns where items have min-width 370px and max-width 400px
const Tableau = styled.div`
  column-fill: balance;
  column-gap: 20px;
  column-width: auto;
  width: 100%;

  column-count: 4;
  max-width: 1780px;

  // Max width for 4 columns:
  // 430 * 4 + 20 * 3 = 1780 + sidebar (260) + padding (40) = 2080

  @media (max-width: 2080px) and (min-width: 1630px) {
    column-count: 3;
    max-width: 1330px;
  }

  // Max width for 2 columns: 
  // 430 * 3 + 20 * 2 = 1330 + sidebar (260) + padding (40) = 1630

  @media (max-width: 1630px) and (min-width: 1180px) {
    column-count: 2;
    max-width: 880px;
  }

  // Max width for 1 column:
  // 430 * 2 + 20 * 1 = 880 + sidebar (260) + padding (40) = 1180

  @media (max-width: 1180px) {
    column-count: 1;
    max-width: 430px;
  }
`;