import get from "lodash/get";
import moment from "moment";
import { WidgetGraphType, WidgetValueType } from "./Widget/types";

// export const WPAColors = {
//   OVERVIEW: "#1C4D82",
//   COLLABORATION_ROOMS: "#177378",
//   SUPPORTING_ROOMS: "#9C315E",
//   ACTIVITY_ZONE: "#367F64",
//   WORKSTATIONS: "#714073",
//   PEOPLE_COUNT: "#14628F",
// };

// export const WPAColorsDark = {
//   OVERVIEW: "#1C4D82",
//   COLLABORATION_ROOMS: "#135053",
//   SUPPORTING_ROOMS: "#67213E",
//   ACTIVITY_ZONE: "#265A47",
//   WORKSTATIONS: "#4F2C4F",
//   PEOPLE_COUNT: "#0D4260",
// };

export const WPAColors = {
  OVERVIEW: "#1C4D82",
  COLLABORATION_ROOMS: "#1B8690",
  SUPPORTING_ROOMS: "#B03C61",
  ACTIVITY_ZONE: "#418A6A",
  WORKSTATIONS: "#7D5087",
  PEOPLE_COUNT: "#2274A4",
};

export const WPAColorsDark = {
  OVERVIEW: "#1C4D82",
  COLLABORATION_ROOMS: "#136267",
  SUPPORTING_ROOMS: "#7A2A43",
  ACTIVITY_ZONE: "#2E614B",
  WORKSTATIONS: "#593961",
  PEOPLE_COUNT: "#185477",
};


// export const WPAColors = {
//   OVERVIEW: "#1C4D82",
//   COLLABORATION_ROOMS: "#20A4B6",
//   SUPPORTING_ROOMS: "#C34664",
//   ACTIVITY_ZONE: "#4E9A6A",
//   WORKSTATIONS: "#88609B",
//   PEOPLE_COUNT: "#2575C3",
// };

// export const WPAColorsDark = {
//   OVERVIEW: "#1C4D82",
//   COLLABORATION_ROOMS: "#147F91",
//   SUPPORTING_ROOMS: "#842A43",
//   ACTIVITY_ZONE: "#316B48",
//   WORKSTATIONS: "#5C426D",
//   PEOPLE_COUNT: "#1B4E7A",
// };

export const getWidgets = (widgetProfile, locationId, page, allPages) => {
  // console.log("getWidgets", locationName, widgetProfile, page);

  const startDate = moment.utc().subtract(1, "month").startOf("month");
  const endDate = moment.utc().subtract(1, "month").endOf("month");
  const lastMonth = moment.utc().subtract(1, "month").startOf("month");
  const lastLastMonth = moment.utc().subtract(2, "month").startOf("month");

  const lastMonthString = lastMonth.format("MMMM");
  const lastLastMonthString = lastLastMonth.format("MMMM");
  const monthDailyCount = endDate.diff(startDate, "days") + 1;

  // Make array from 1 to monthDailyCount
  const monthDailyArray = Array.from({ length: monthDailyCount }, (v, k) => k + 1);

  // Date object with all relevant dates
  const dates = {
    startDate,
    endDate,
    lastMonth,
    lastLastMonth,
    lastMonthString,
    lastLastMonthString,
    monthDailyArray
  };

  // When in Overview - return predefined widgets
  if (page.id === "overview") {
    let widgetBlueprints = get(widgetProfile, "overviewWidgets", []);

    // Filter blueprints based on locationId
    widgetBlueprints = widgetBlueprints?.filter(widgetBlueprint => widgetBlueprint.locationIds?.includes(locationId));

    return widgetBlueprints.map(widgetBlueprint => {
      const correspondingPage = allPages.find(page => page.id === widgetBlueprint.wpaCategoryId || (widgetBlueprint.wpaCategoryId === undefined && widgetBlueprint.customTagId !== undefined && page.id === "peopleCount"));
      return getWidgetFromBlueprint(widgetBlueprint, dates, correspondingPage);
    });
  }
  else if (page.id === "peopleCount") {
    const locationPeopleCountCustomTags = get(widgetProfile, "locationPeopleCountCustomTags", []);

    let customTagId = locationPeopleCountCustomTags.find(tag => tag.locationId === locationId)?.customTagId;
    if (!customTagId) {
      customTagId = widgetProfile.defaultPeopleCountCustomTagId;
    }

    let peopleCountWidgetBlueprints = [
      {
        type: WidgetGraphType.VerticalBar,
        source: "peopleCountIntraday",
        customTagId: customTagId
      },
      {
        type: WidgetGraphType.VerticalBar,
        source: "peopleCountWeekday",
        customTagId: customTagId
      },
      {
        type: WidgetGraphType.HorizontalBar,
        source: "peopleCountMonth",
        customTagId: customTagId
      },
      {
        type: WidgetGraphType.MonthlyDurationAtCapacity,
        source: "atCapacityMinutes",
        customTagId: customTagId
      }
    ];

    // Filter standard blueprints based on widgetProfile.hiddenWidgets
    peopleCountWidgetBlueprints = peopleCountWidgetBlueprints.filter(widgetBlueprint => {
      return !widgetProfile.hiddenWidgets?.find(hw => hw.source === widgetBlueprint.source && hw.type === widgetBlueprint.type && hw.customTagId === widgetBlueprint.customTagId && (hw.locationIds.includes(locationId) || hw.everywhere));
    });

    return peopleCountWidgetBlueprints.map(widgetBlueprint => {
      return getWidgetFromBlueprint(widgetBlueprint, dates, page);
    });
  }
  
  let widgetBlueprints = [
    // {
    //   type: WidgetGraphType.HorizontalCompareMonths,
    //   source: "utilizationDistributionMonth"
    // },
    {
      type: WidgetGraphType.HorizontalBar,
      source: "utilizationMonth"
    },
    {
      type: WidgetGraphType.VerticalBar,
      source: "utilizationWeekday"
    },
    {
      type: WidgetGraphType.UtilizationSparklineMonth,
      source: "utilizationDay"
    },
    {
      type: WidgetGraphType.MonthlyUtilizationDistribtion,
      source: "utilizationDistributionMonth"
    },
  ];

  if (page.name !== "Workstations") {
    widgetBlueprints.push(
      {
        type: WidgetGraphType.DynamicValueList,
        source: "utilizationMonthTopLocations"
      },
      {
        type: WidgetGraphType.DynamicValueList,
        source: "utilizationMonthBottomLocations"
      }
    );
  }

  if (page.name !== "ActivityZone") {
    widgetBlueprints.push(
      {
        type: WidgetGraphType.MonthlyDurationEmpty,
        source: "notUtilizedMinutes"
      },
      {
        type: WidgetGraphType.MonthlyDurationFull,
        source: "fullyUtilizedMinutes"
      }
    );
  }

  // Add wpaCategoryId from page.id
  widgetBlueprints = widgetBlueprints.map(widgetBlueprint => {
    return {
      ...widgetBlueprint,
      wpaCategoryId: page.id
    };
  });

  // Filter standard blueprints based on widgetProfile.hiddenWidgets
  widgetBlueprints = widgetBlueprints.filter(widgetBlueprint => {
    return !widgetProfile.hiddenWidgets?.find(hw => hw.source === widgetBlueprint.source && hw.type === widgetBlueprint.type && hw.wpaCategoryId === widgetBlueprint.wpaCategoryId && (hw.locationIds.includes(locationId) || hw.everywhere));
  });

  return widgetBlueprints.map(widgetBlueprint => {
    return getWidgetFromBlueprint(widgetBlueprint, dates, page);
  });
}

const getWidgetFromBlueprint = (widgetBlueprint, dates, selectedPage) => {
  
  let {
    startDate,
    endDate,
    lastMonth,
    lastLastMonth,
    lastMonthString,
    lastLastMonthString,
    monthDailyArray
  } = dates;
  
  let widget = {
    type: widgetBlueprint.type,
    id: widgetBlueprint.source,
    wpaCategoryId: widgetBlueprint.wpaCategoryId,
    customTagId: widgetBlueprint.customTagId,
    subtitle: lastMonth.format("MMMM YYYY"),
  };
  
  if (selectedPage) {
    widget.wpaCategoryName = selectedPage.name;
  }
  else if (widget.customTagId) {
    widget.wpaCategoryName = "People count";
  }

  const color = selectedPage?.color ?? "#333";
  const darkColor = selectedPage?.darkColor ?? "#999";
  const resourceName = selectedPage?.resourceName ?? "rooms";
  const singleResourceName = selectedPage?.singleResourceName ?? "room";

  switch (widget.id) {
    case "peopleCountIntraday":
      widget.valueType = WidgetValueType.Count;
      widget.dateKey = "hour";
      widget.title = "Daily people count";
      widget.description = `The highest number of people by hour of day in $locationName.`,
      widget.dataLayout = [
        { property: "peakPeak", hour: 7, color: color, description: "07-08" },
        { property: "peakPeak", hour: 8, color: color, description: "" },
        { property: "peakPeak", hour: 9, color: color, description: "" },
        { property: "peakPeak", hour: 10, color: color, description: "" },
        { property: "peakPeak", hour: 11, color: color, description: "" },
        { property: "peakPeak", hour: 12, color: color, description: "" },
        { property: "peakPeak", hour: 13, color: color, description: "" },
        { property: "peakPeak", hour: 14, color: color, description: "" },
        { property: "peakPeak", hour: 15, color: color, description: "" },
        { property: "peakPeak", hour: 16, color: color, description: "16-17" }
      ];
      break;
    case "peopleCountWeekday":
      widget.valueType = WidgetValueType.Count;
      widget.dateKey = "weekday";
      widget.title = "Weekly people count";
      widget.description = "The highest number of people in by day of the week in $locationName."
      widget.dataLayout = [
        { property: "peakPeak", weekday: 1, color: color, description: "Monday" },
        { property: "peakPeak", weekday: 2, color: color, description: "" },
        { property: "peakPeak", weekday: 3, color: color, description: "" },
        { property: "peakPeak", weekday: 4, color: color, description: "" },
        { property: "peakPeak", weekday: 5, color: color, description: "Friday" },
      ];
      break;
    case "peopleCountMonth":
      widget.valueType = WidgetValueType.Count;
      widget.title = "Monthly people count";
      widget.description = "The highest number of people in $locationName.";
      widget.dataLayout = [
        { property: "peakPeak", color: color, description: "at peak" },
        { property: "avgPeak", color: darkColor, description: "average daily peak" },
      ];
      break;
    case "atCapacityMinutes":
      widget.valueType = WidgetValueType.Minutes;
      widget.title = "Duration at capacity";
      widget.description = "The total duration $locationName were at 100% capacity.";
      widget.resourceName = resourceName;
      widget.singleResourceName = singleResourceName;
      widget.dateKey = "date";
      widget.dataLayout = [
        { property: "duration", date: lastMonth.format("YYYY-MM-DD"), description: lastMonthString, color: darkColor },
        { property: "duration", date: lastLastMonth.format("YYYY-MM-DD"), description: lastLastMonthString, color: color }
      ];
      break;
    case "utilizationDistributionMonth":
      widget.valueType = WidgetValueType.Count;
      widget.dateKey = "date";
      widget.resourceName = resourceName;
      widget.singleResourceName = singleResourceName;

      if (widget.type === WidgetGraphType.HorizontalCompareMonths) {
        widget.title = `Unused ${resourceName}`;
        widget.description = `The number of ${resourceName} that had peak occupancy above 80% at least one workday in $locationName.`;
        widget.dataLayout = [
          { property: "combinedDistribution[0].count", change: "combinedDistribution[0].countChange", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "0-10%" },
          { property: "combinedDistribution[1].count", change: "combinedDistribution[1].countChange", date: lastMonth.format("YYYY-MM-DD"), color: darkColor, description: "10-20%" }
        ];
      }
      else {
        widget.title = "Occupancy distribution";
        widget.dataLayout = [
          { property: "combinedDistribution[0].count", change: "combinedDistribution[0].change", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "0-20%" },
          { property: "combinedDistribution[1].count", change: "combinedDistribution[1].change", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "20-40%" },
          { property: "combinedDistribution[2].count", change: "combinedDistribution[2].change", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "40-60%" },
          { property: "combinedDistribution[3].count", change: "combinedDistribution[3].change", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "60-80%" },
          { property: "combinedDistribution[4].count", change: "combinedDistribution[4].change", date: lastMonth.format("YYYY-MM-DD"), color: color, description: "80-100%" },      
        ];
      }
      
      break;
    case "utilizationMonthTopLocations":
      widget.valueType = WidgetValueType.Percentage;
      widget.dateKey = "date";
      widget.title = `Most popular ${resourceName}`;
      widget.description = `The ${resourceName} with the highest occupancy in $locationName.`;
      widget.dataLayout = { property: "avg", date: lastMonth.format("YYYY-MM-DD"), color: color, nameProperty: "locationName" };
      break;
    case "utilizationMonthBottomLocations":
      widget.valueType = WidgetValueType.Percentage;
      widget.dateKey = "date";
      widget.title = `Least popular ${resourceName}`;
      widget.description = `The ${resourceName} with the lowest occupancy in $locationName.`;
      widget.dataLayout = { property: "avg", date: lastMonth.format("YYYY-MM-DD"), color: color, nameProperty: "locationName" };
      break;
    case "utilizationWeekday":
      widget.valueType = WidgetValueType.Percentage;
      widget.dateKey = "weekday";
      widget.title = "Weekly peak utilization";
      widget.description = "The highest utilization reached each weekday in $locationName.";
      widget.dataLayout = [
        { property: "peakPeak", weekday: 1, color: color, description: "Monday" },
        { property: "peakPeak", weekday: 2, color: color, description: "" },
        { property: "peakPeak", weekday: 3, color: color, description: "" },
        { property: "peakPeak", weekday: 4, color: color, description: "" },
        { property: "peakPeak", weekday: 5, color: color, description: "Friday" },
      ];
      break;
    case "utilizationDay":
      widget.valueType = WidgetValueType.Percentage;
      widget.title = "Monthly peak utilization";
      widget.color = color;
      widget.dataLayout = monthDailyArray.map((_, index) => ({ property: "peakPeak", description: index === 0 ? startDate.format("D. MMMM") : (index === monthDailyArray.length - 1 ? endDate.format("D. MMMM") : "") }));
      break;
    case "utilizationMonth":
      widget.valueType = WidgetValueType.Percentage;
      widget.title = "Monthly peak and average utilization";
      widget.description = "The highest and average utilization in $locationName.";
      widget.resourceName = resourceName;
      widget.singleResourceName = singleResourceName;
      widget.dataLayout = [
        { property: "peakPeak", color: color, description: "at peak" },
        { property: "avgPeak", color: darkColor, description: "average daily peak" },
      ];
      break;
    case "notUtilizedMinutes":
      widget.valueType = WidgetValueType.Minutes;
      widget.title = "Everything available";
      widget.resourceName = resourceName;
      widget.singleResourceName = singleResourceName;
      widget.dateKey = "date";
      widget.dataLayout = [
        { property: "duration", date: lastMonth.format("YYYY-MM-DD"), description: lastMonthString, color: darkColor },
        { property: "duration", date: lastLastMonth.format("YYYY-MM-DD"), description: lastLastMonthString, color: color }
      ];
      break;
    case "fullyUtilizedMinutes":
      widget.valueType = WidgetValueType.Minutes;
      widget.title = "Everything occupied";
      widget.resourceName = resourceName;
      widget.singleResourceName = singleResourceName;
      widget.dateKey = "date";
      widget.dataLayout = [
        { property: "duration", date: lastMonth.format("YYYY-MM-DD"), description: lastMonthString, color: darkColor },
        { property: "duration", date: lastLastMonth.format("YYYY-MM-DD"), description: lastLastMonthString, color: color }
      ];
      break;
    default:
      break;
  }
  
  // Apply all overrides
  widget = { ...widget, ...widgetBlueprint.config };

  return widget;
}