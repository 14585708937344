import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface ListCardProps {
  title?: string;
  minWidth?: string;
  showHeaderDivider?: boolean;
  buttons?: { name: string, icon: IconDefinition, onClick: () => void }[];
  children?: React.ReactNode;
  footer?: React.ReactNode;
  useBottomPadding?: boolean;
}

const ListCard: React.FC<ListCardProps> = ({ title, minWidth = "100%", showHeaderDivider, buttons = [], children, footer, useBottomPadding = false }) => {

  if (title) {
    return (
      <Container $useBottomPadding={useBottomPadding}>
        <ContentWrapper $minWidth={minWidth}>
          <Header $showHeaderDivider={showHeaderDivider}>
            <Title>
              { title }
            </Title>
            <Buttons>
              { buttons.map((button, index) => (
                <Button title={button.name} key={index} onClick={button.onClick}>
                  <FontAwesomeIcon className="icon" icon={button.icon} color="#6b7073" fontSize={21} />
                </Button>
              ))}
            </Buttons>
          </Header>
          <Content>
            { children }
          </Content>
        </ContentWrapper>
      </Container>
    );
  }

  if (footer) {
    return (
      <Container $useBottomPadding={useBottomPadding}>
        <FooterContainer>
          <ContentWrapper $minWidth={minWidth}>
            <Content>
              { children }
            </Content>
          </ContentWrapper>
          <Footer>
            { footer }
          </Footer>
        </FooterContainer>
      </Container>
    );
  }

  return (
    <Container $useBottomPadding={useBottomPadding}>
      <ContentWrapper $minWidth={minWidth}>
        <Content>
          { children }
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default ListCard;

const Container = styled.div<{ $useBottomPadding?: boolean }>`
  position: relative;
  display: block;
  box-sizing: border-box;
  break-inside: avoid;

  ${props => props.$useBottomPadding && `
    padding-bottom: 20px;
  `}
`;

const FooterContainer = styled.div`
  position: relative;
  display: block;
  background-color: rgb(225, 225, 225);
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  break-inside: avoid;
`;

const Footer = styled.div`
  display: flex;
  padding: 3px 10px 5px 10px;
  justify-content: space-between;
`;

const ContentWrapper = styled.div<{ $minWidth: string }>`
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  overflow: hidden;
  box-sizing: border-box;

  width: ${props => props.$minWidth};
  min-width: ${props => props.$minWidth};
`;

const Header = styled.div<{ $showHeaderDivider?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 5px;
  height: 50px;
  border-bottom: ${props => props.$showHeaderDivider ? '1px solid #ddd' : 'none'};
  box-sizing: border-box;
`;

const Title = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
  line-height: 50px;
`;

const Content = styled.div`
  display: block;
  background-color: #fff;
  box-sizing: border-box
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &:first-child {
    // margin-top: 30px;
  }

  &:hover .icon {
    color: #000; // Change #yourDesiredHoverColor to the color you want
  }
`;
