import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubNavigation from "../../components/SubNavigation";
import SearchLocations from "./searchLocations";
import SearchSensors from "./searchSensors";
import SearchGateways from "./searchGateways";
import NotFound from "../NotFound";
import WidgetsPage from "./widgetsPage";
import styled from "styled-components";

class HomeContainer extends Component {

  render() {
    // console.log("HomeContainer", this.props);
    const { companyId } = this.props.match.params;

    const subNavigationLinks = [];

    // Add query params to widgets if applicable
    const widgetQueryParams = this.props.widgetQueryParams ? { ...this.props.widgetQueryParams } : undefined;
    subNavigationLinks.push({ pathname: `/companies/${companyId}/home/widgets`, queryParams: widgetQueryParams, label: "Widgets" });

    // Add query params to locations if applicable
    const locationQueryParams = this.props.locationQueryParams ? { ...this.props.locationQueryParams } : undefined;
    subNavigationLinks.push({ pathname: `/companies/${companyId}/home/locations`, queryParams: locationQueryParams, label: "Locations" });

    // Only show sensors and gateways if the user has IT Admin role
    if (this.props.auth.hasITAdminRole) {
      const sensorQueryParams = this.props.sensorQueryParams ? { ...this.props.sensorQueryParams } : undefined;
      const gatewayQueryParams = this.props.gatewayQueryParams ? { ...this.props.gatewayQueryParams } : undefined;
    
      subNavigationLinks.push({ pathname: `/companies/${companyId}/home/sensors`, queryParams: sensorQueryParams, label: "Sensors" });
      subNavigationLinks.push({ pathname: `/companies/${companyId}/home/gateways`, queryParams: gatewayQueryParams, label: "Gateways" });
    }

    return (
      <Page>
        <SubNavBackground>
          <SubNavigation links={subNavigationLinks} slim normalMargin />
        </SubNavBackground>

        <Switch>
          <Redirect exact from="/companies/:companyId/home" to={`/companies/${companyId}/home/widgets`} />
          <Redirect exact from="/companies/:companyId/home/dash" to={`/companies/${companyId}/dashboard`} />
          <Route path="/companies/:companyId/home/widgets" component={WidgetsPage} />
          <Route path="/companies/:companyId/home/locations" component={SearchLocations} />
          <Route path="/companies/:companyId/home/sensors" component={SearchSensors} />
          <Route path="/companies/:companyId/home/gateways" component={SearchGateways} />
          <Route component={NotFound} />
        </Switch>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    widgetQueryParams: state.widgets.currentQueryParams,
    locationQueryParams: state.locations.currentQueryParams,
    sensorQueryParams: state.sensors.currentQueryParams,
    gatewayQueryParams: state.gateways.currentQueryParams
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
   }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));

const Page = styled.div`
  height: calc(100vh - 65px);
  background-color: #f6f6f6;
`;

const SubNavBackground = styled.div`
  background-color: white;
`;