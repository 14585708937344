import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import Loader from "../../components/Loader";
import FrontPageSearchBox from "../../components/FrontPageSearchBox";
import Locations from "./ContentViews/locations";
import * as locationActions from "../../actions/locations";
import * as widgetActions from "../../actions/widgets";
import styled from "styled-components";

class SearchLocations extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: params.get("search") ?? "",
      displayType: params.get("type"),
      dataSource: params.get("show") ?? "avg",
      hasLoadedView: false,
    };

    this.storeQueryParams = this.storeQueryParams.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onDisplayTypeChanged = this.onDisplayTypeChanged.bind(this);
    this.onDataSourceChanged = this.onDataSourceChanged.bind(this);
    this.onGotoLocation = this.onGotoLocation.bind(this);

    this.storeQueryParams();
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Search`;

    // Set moment locale every time the component mounts
    moment.locale("en");

    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    // console.log("SearchLocations.componentDidMount.props", this.props);
    // console.log("SearchLocations.componentDidMount.state", this.state);
    if (this.props.customTags !== null && !isEmpty(this.props.locationHierarchy)) {
      
      if (this.props.searchText !== this.state.search) {
        this.props.homeLocationsSearch(this.state.search);
      }
      else {
        this.fetchData();
      }

      this.setState({ hasLoadedView: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("SearchLocations.componentDidUpdate.props", this.props);
    // console.log("SearchLocations.componentDidUpdate.state", this.state);

    // If we haven't loaded the view yet, and we have the custom tags and location hierarchy, filter locations
    if (!this.state.hasLoadedView) {
      if (this.props.customTags !== null && !isEmpty(this.props.locationHierarchy)) {
        
        if (this.props.searchText !== this.state.search) {
          this.props.homeLocationsSearch(this.state.search);
        }
        else {
          this.fetchData();
        }
        
        this.setState({ hasLoadedView: true });
      }

      return;
    }

    const searchChanged = this.props.searchText !== prevProps.searchText;
    if (searchChanged || this.state.displayType !== prevState.displayType) {
      this.fetchData();
    }
  }

  storeQueryParams() {
    const queryParams = {};
    const params = new URLSearchParams();

    if (!isEmpty(this.state.search)) {
      queryParams.search = this.state.search;
      params.set("search", this.state.search);
    }

    if (!isEmpty(this.state.displayType)) {
      queryParams.type = this.state.displayType;
      params.set("type", this.state.displayType);
    }

    params.set("show", this.state.dataSource);

    this.props.setHomeLocationSearch(queryParams);

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  performSearch() {
    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    this.props.homeLocationsSearch(this.state.search);
    this.storeQueryParams();
  }

  onDisplayTypeChanged(type) {
    this.setState({ displayType: type, offset: 0 }, this.storeQueryParams);
  }

  onDataSourceChanged(dataSource) {
    this.setState({ dataSource }, this.storeQueryParams);
  }

  onSearchChange(text) {
    this.setState({ search: text, offset: 0 });
  }

  onSearchClear() {
    this.setState({ search: "", offset: 0 }, this.performSearch);
  }

  onRemoveTagClick(tag) {
    if (tag.id === "???") {
      this.performSearch();
    }
  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.performSearch();
    }
  }

  onGotoLocation(event, locationId) {
    let link = `/companies/${this.props.match.params.companyId}/locations/${locationId}/locations/`;

    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  fetchData() {
    // console.log("SearchLocations.fetchData");
    const locations = this.props.locations ?? [];
    let locationIds = locations.filter(location => location.type != "asset.workstation").map(location => location.id);

    // Filter out locations that already have data
    if (this.state.displayType === "occupancy" || this.state.displayType === "lowcount" || this.state.displayType === "highcount") {
      locationIds = locationIds.filter(id => isEmpty(this.props.locationsOccupancyWeekdays[id]));

      if (locationIds.length > 0) {
        this.props.getLocationsOccupancyWeekdays(locationIds);
      }
    }
    else if (this.state.displayType === "people") {
      locationIds = locationIds.filter(id => isEmpty(this.props.locationsMaxPeopleWeekdays[id]));

      if (locationIds.length > 0) {
        this.props.getLocationsMaxPeopleWeekdays(locationIds);
      }
    }
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.SearchLocations.state", this.state);
    // console.log("Home.SearchLocations.props", this.props);

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let customTags = [];

    const searchBox = (
      <FrontPageSearchBox
        query={{ customTags, text: this.state.search, placeholder: "What are you looking for?" }}
        onQueryChange={this.onSearchChange}
        onSearchClick={this.performSearch}
        onKeyDown={this.onSearchKeyPress}
        onClear={this.onSearchClear}
        onRemoveTagClick={this.onRemoveTagClick}
      />
    );

    const heading = (
      <div style={{ marginLeft: "auto", marginRight: "auto", flexGrow: 1, maxWidth: "800px", marginTop: "60px", marginBottom: "60px" }}>
        {searchBox}
      </div>
    );

    return (
      <OuterContainer>
        <InnerContainer>
          {heading}
          <ContentContainer>
            <Locations
              displayType={this.state.displayType ?? "all"}
              searchText={this.props.searchText}
              dataSource={this.state.dataSource}
              onDisplayTypeChanged={this.onDisplayTypeChanged}
              onDataSourceChanged={this.onDataSourceChanged}
              onGotoLocation={this.onGotoLocation}
            />
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    locations: state.locations.homeSearchLocations,
    searchText: state.locations.homeSearchText,
    customTags: state.locations.customTags,
    locationsOccupancyWeekdays: state.locations.locationsOccupancyWeekdays,
    locationsMaxPeopleWeekdays: state.locations.locationsMaxPeopleWeekdays,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocationsOccupancyWeekdays: widgetActions.getLocationsOccupancyWeekdays,
    getLocationsMaxPeopleWeekdays: widgetActions.getLocationsMaxPeopleWeekdays,
    homeLocationsSearch: locationActions.homeLocationsSearch,
    setHomeLocationSearch: locationActions.setHomeLocationSearch,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocations);

const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: calc(100vh - 115px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;