import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Authentication from "../../components/Auth/auth";
import LocationSwitch from "../Locations";
import DisplayContainer from "../Displays";
import ScreenContainer from "../Screens";
import ScreenGroupContainer from "../ScreenConfig";
import OrgContainer from "../Organization";
import InstallationSwitch from "../Installation";
import SensorContainer from "../Sensor/container";
import GatewayContainer from "../Gateway/container";
import LocationTag from "../LocationTag";
import SensorTag from "../SensorTag";
import App from "../App";
import Filters from "../Filters";
import EditContent from "../EditContent";
import Scan from "../Scan";
import NotFound from "../NotFound";
import Signin from "../Signin";
import Account from "../Account";
import Dashboard from "../Dashboard";
import Feedback from "../Feedback";
import Insights from "../Insights";
import Reports from "../Reports";
import ConfigDisplay from "../ConfigDisplay";
import Home from "../Home";
import MapContainer from "../../components/MapContainer";
import NewCompany from "../Companies/newCompany";
import TermsOfService from "../TermsOfService";
import PrivacyPolicyWeb from "../PrivacyPolicyWeb";
import PrivacyPolicyApp from "../PrivacyPolicyApp";

const Main = () => (
  <div style={{ flex: 1, overflow: "hidden" }}>
    <Switch>
      <Route exact path="/" children={<Redirect to="/home" />} />
      <Route exact path="/companies/:companyId" children={(props) => <Redirect to={`/companies/${props.match.params.companyId}/home`} />} />
      <Route path="/companies/:companyId/sensors/:id" children={(props) => (<Authentication {...props}><MapContainer {...props}><SensorContainer {...props} /></MapContainer></Authentication>)} />
      <Route path="/companies/:companyId/gateways/:id" children={(props) => (<Authentication {...props}><MapContainer {...props}><GatewayContainer {...props} /></MapContainer></Authentication>)} />
      <Route path="/companies/:companyId/location-tags/:id?" children={(props) => (<Authentication {...props}><LocationTag {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/sensor-tags/:id?" children={(props) => (<Authentication {...props}><SensorTag {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/insights/view/:id" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/insights/edit/:id" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/insights" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/app/content/edit/:id?" children={(props) => (<Authentication {...props}><EditContent {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/app" children={(props) => (<Authentication {...props}><App {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/filters" children={(props) => (<Authentication {...props}><Filters {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/locations" children={(props) => (<Authentication {...props}><MapContainer {...props}><LocationSwitch {...props} /></MapContainer></Authentication>)} />
      <Route path="/companies/:companyId/org" children={(props) => (<Authentication {...props}><OrgContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/installation" children={(props) => (<Authentication {...props}><InstallationSwitch {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/displays" children={(props) => (<Authentication {...props}><DisplayContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId" children={(props) => (<Authentication {...props}><ScreenGroupContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/screens/edit/:screenGroupId" children={(props) => (<Authentication {...props}><ScreenGroupContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/screens/displays" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/screens/waiting" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/screens/:screenGroupId?" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/scan" children={(props) => (<Authentication {...props}><Scan {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/config-display" children={(props) => (<Authentication {...props}><ConfigDisplay {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/home" children={(props) => (<Authentication {...props}><Home {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/reports" children={(props) => (<Authentication {...props}><Reports {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/account" children={(props) => (<Authentication {...props}><Account {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/new-company" children={(props) => (<Authentication {...props}><NewCompany {...props} /></Authentication>)} />
      <Route path="/companies/:companyId/dashboard" children={(props) => (<Authentication {...props}><Dashboard {...props} /></Authentication>)} />

      <Route path="/companies/:companyId/support" children={(props) => (<Feedback {...props} />)} />
      <Route path="/companies/:companyId/terms-of-service" children={(props) => (<TermsOfService {...props} />)} />
      <Route path="/companies/:companyId/privacy-policy" children={(props) => (<PrivacyPolicyWeb {...props} />)} />
      <Route path="/companies/:companyId/privacy-policy-app" children={(props) => (<PrivacyPolicyApp {...props} />)} />
      <Route path="/companies/:companyId/signin" children={(props) => (<Signin {...props} />)} />
      
      <Route path="/sensors/:id" children={(props) => (<Authentication {...props}><SensorContainer {...props} /></Authentication>)} />
      <Route path="/gateways/:id" children={(props) => (<Authentication {...props}><GatewayContainer {...props} /></Authentication>)} />
      <Route path="/location-tags/:id?" children={(props) => (<Authentication {...props}><LocationTag {...props} /></Authentication>)} />
      <Route path="/sensor-tags/:id?" children={(props) => (<Authentication {...props}><SensorTag {...props} /></Authentication>)} />
      <Route path="/insights/view/:id" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/insights/edit/:id" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/insights" children={(props) => (<Authentication {...props}><Insights {...props} /></Authentication>)} />
      <Route path="/app/content/edit/:id?" children={(props) => (<Authentication {...props}><EditContent {...props} /></Authentication>)} />
      <Route path="/app" children={(props) => (<Authentication {...props}><App {...props} /></Authentication>)} />
      <Route path="/filters" children={(props) => (<Authentication {...props}><Filters {...props} /></Authentication>)} />
      <Route path="/locations" children={(props) => (<Authentication {...props}><LocationSwitch {...props} /></Authentication>)} />
      <Route path="/org" children={(props) => (<Authentication {...props}><OrgContainer {...props} /></Authentication>)} />
      <Route path="/installation" children={(props) => (<Authentication {...props}><InstallationSwitch {...props} /></Authentication>)} />
      <Route path="/displays" children={(props) => (<Authentication {...props}><DisplayContainer {...props} /></Authentication>)} />
      <Route path="/screens/edit/:screenGroupId/views/:screenViewId" children={(props) => (<Authentication {...props}><ScreenGroupContainer {...props} /></Authentication>)} />
      <Route path="/screens/edit/:screenGroupId" children={(props) => (<Authentication {...props}><ScreenGroupContainer {...props} /></Authentication>)} />
      <Route path="/screens/displays" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/screens/waiting" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/screens/:screenGroupId?" children={(props) => (<Authentication {...props}><ScreenContainer {...props} /></Authentication>)} />
      <Route path="/scan" children={(props) => (<Authentication {...props}><Scan {...props} /></Authentication>)} />
      <Route path="/config-display" children={(props) => (<Authentication {...props}><ConfigDisplay {...props} /></Authentication>)} />
      <Route path="/home" children={(props) => (<Authentication {...props}><Home {...props} /></Authentication>)} />
      <Route path="/reports" children={(props) => (<Authentication {...props}><Reports {...props} /></Authentication>)} />
      <Route path="/account" children={(props) => (<Authentication {...props}><Account {...props} /></Authentication>)} />
      <Route path="/dashboard" children={(props) => (<Authentication {...props}><Dashboard {...props} /></Authentication>)} />
     
      <Route path="/support" children={(props) => (<Feedback {...props} />)} />
      <Route path="/terms-of-service" children={(props) => (<TermsOfService {...props} />)} />
      <Route path="/privacy-policy" children={(props) => (<PrivacyPolicyWeb {...props} />)} />
      <Route path="/privacy-policy-app" children={(props) => (<PrivacyPolicyApp {...props} />)} />
      <Route path="/signin" children={(props) => (<Signin {...props} />)} />
      
      <Route children={<NotFound />} />
    </Switch>
  </div>
);

export default Main;
