import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";

class HomeHeader extends Component {

  constructor(props) {
    // console.log("HomeHeader.constructor()", props);
    super(props);
    this.state = {
      hoverStar: false
    };

    this.onHoverStar = this.onHoverStar.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  static propTypes = {
    pages: PropTypes.array.isRequired,
    selectedPageId: PropTypes.string,
    selectedLocation: PropTypes.object,
    favoriteLocationId: PropTypes.string,
    onSelectedPageChanged: PropTypes.func.isRequired,
    onToggleFavorite: PropTypes.func.isRequired,
  };

  onHoverStar(hover) {
    this.setState({ hoverStar: hover });
  }

  onPageClick(page) {
    this.props.onPageClick(page);
  }

  onKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      console.log("Tab pressed");
    }
  }

  formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u202F"); // \u202F is the Unicode for thin space
  }

  render() {
    // console.log("HomeHeader.render()", this.props);
    const { selectedLocation } = this.props;

    if (!selectedLocation) {
      return null;
    }

    // Get favorite location style
    const selectedLocationId = get(selectedLocation, "id", "");
    const selectedLocationName = get(selectedLocation, "name", "");
    const star = this.props.favoriteLocationId === selectedLocationId ? faStar : faEmptyStar;
    const starColor = this.state.hoverStar ? "#aaa" : this.props.favoriteLocationId === selectedLocationId ? "#f5a623" : "#6b7073";

    const pageMenu = this.props.pages.map((page) => (
      <MenuButton
        key={page.id}
        onClick={() => this.props.onSelectedPageChanged(page.id)}
        $isSelected={this.props.selectedPageId === page.id}
        $selectedColor={page.color}
        $color={"#333"}>
        <span style={{ userSelect: "none" }}>{page.name}</span>
      </MenuButton>
    ));

    let descriptionElement = null;

    let locationTypeString = get(selectedLocation, "type", "");
    locationTypeString = locationTypeString.charAt(0).toUpperCase() + locationTypeString.slice(1);

    if (selectedLocation.capacity && selectedLocation.squareMeters) {
      const capacity = this.formatNumber(selectedLocation.capacity);
      const squareMeters = this.formatNumber(selectedLocation.squareMeters);
      descriptionElement = <Description>{locationTypeString} with a capacity of {capacity} people in {squareMeters} m².</Description>;
    }
    else if (selectedLocation.capacity) {
      const capacity = this.formatNumber(selectedLocation.capacity);
      descriptionElement = <Description>{locationTypeString} with a capacity of {capacity} people.</Description>;
    }
    else if (selectedLocation.squareMeters) {
      const squareMeters = this.formatNumber(selectedLocation.squareMeters);
      descriptionElement = <Description>{locationTypeString} with an area of {squareMeters} m².</Description>;
    }
    else {
      descriptionElement = <Description>{locationTypeString} with unknown capacity and area.</Description>;
    }

    return (
      <Container>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h1 style={{ display: "inline-block" }}>{selectedLocationName}</h1>
            <StarContainer onClick={(event) => { this.props.onToggleFavorite(selectedLocationId); event.stopPropagation(); }} onMouseEnter={() => this.onHoverStar(true)} onMouseLeave={() => this.onHoverStar(false)}>
              <FontAwesomeIcon icon={star} fontSize={22} color={starColor} />
            </StarContainer>
            {descriptionElement}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", columnGap: "10px", flexWrap: "wrap" }}>
          {pageMenu}
        </div>
      </Container>
    );
  }
}

export default HomeHeader;

const Container = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 1780px;

  @media (max-width: 2080px) and (min-width: 1630px) {
    max-width: 1330px;
  }

  @media (max-width: 1630px) and (min-width: 1180px) {
    max-width: 880px;
  }

  @media (max-width: 1180px) {
    max-width: 430px;
  }
`;

const MenuButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  // margin-bottom: 10px;
  background-color: ${(props) => props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : "#fff"};
  border-color: ${(props) => props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : (props.$color ?? "#1c4d82")};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 15px;

  span {
    color: ${(props) => props.$isSelected ? "#fff" : (props.$color ?? "#1c4d82")};
    font-weight: 600;
    font-size: 15px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  padding: 0px 0px 0px 10px;
  align-items: start;
  justify-content: start;
  cursor: pointer;
`;

const Description = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;