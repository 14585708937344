import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import { cyrb53 } from "../helpers";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, WPA_URL, COOKIE_PREFIX, MARTS_URL } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

const widgetMockups = (companyId) => {
  if (companyId === "5bd6f1eaf59309f2e2198fe9") {
    return {
      defaultPeopleCountCustomTagId: "66fa4687001d7ca1bef3b00a",
      overviewWidgets: [
        {
          type: "DynamicValueList",
          source: "utilizationMonthTopLocations",
          wpaCategoryId: "643f8a02edfd30f1428cb985"
        },
        {
          type: "VerticalBar",
          source: "peopleCountIntraday",
          customTagId: "66fa4687001d7ca1bef3b00a",
          locationIds: ["5c7cdb10344ca60005ee9610"]
        }
      ]
    }
  }
  else if (companyId === "5bd6f1eaf59309f2e2198fe8") {
    return {
      defaultPeopleCountCustomTagId: "65375e826433c5813d2d4b02",
      overviewWidgets: [
        {
          type: "DynamicValueList",
          source: "utilizationMonthTopLocations",
          wpaCategoryId: "643f8a02edfd30f1428cb981"
        },
        {
          type: "VerticalBar",
          source: "peopleCountIntraday",
          customTagId: "65375e826433c5813d2d4b02",
          locationIds: ["5c7cdb10344ca60005ee9610"]
        }
      ]
    }
  }
  else if (companyId === "5bd6f1eaf59309f2e2198fe6") {
    return {
      defaultPeopleCountCustomTagId: "666959ebffded88140b98bf6",
      overviewWidgets: [
        {
          type: "HorizontalBar",
          source: "utilizationMonth",
          wpaCategoryId: "643f8a02edfd30f1428cb97a",
          locationIds: ["5de8ccce1368050006ba8360"]
        },
        {
          type: "HorizontalBar",
          source: "utilizationMonth",
          wpaCategoryId: "643f8a02edfd30f1428cb978",
          locationIds: ["5de8ccce1368050006ba8360"]
        },
        {
          type: "HorizontalBar",
          source: "utilizationMonth",
          wpaCategoryId: "643f8a02edfd30f1428cb979",
          locationIds: ["5de8ccce1368050006ba8360"]
        },
        {
          type: "HorizontalBar",
          source: "utilizationMonth",
          wpaCategoryId: "643f8a02edfd30f1428cb977",
          locationIds: ["5de8ccce1368050006ba8360"]
        },
        {
          type: "HorizontalBar",
          source: "peopleCountMonth",
          customTagId: "666959ebffded88140b98bf6",
          locationIds: ["5de8ccce1368050006ba8360"]
        },
        {
          type: "VerticalBar",
          source: "peopleCountIntraday",
          customTagId: "666959ebffded88140b98bf6",
          locationIds: ["5de8c9fd1368050006ba833e"]
        }
      ]
    }
  }
  else if (companyId === "5c6e52c783bd8000084972da") {
    return {
      locationPeopleCountCustomTags: [
        {
          locationId: "5c7cdb19344ca60005ee9614",
          customTagId: "660be2dfdc45295fc6a93a85"
        }
      ],
      hiddenWidgets: [
        {
          type: "DynamicValueList",
          source: "utilizationMonthTopLocations",
          wpaCategoryId: "6423fc975d0a7a525fad3075",
          locationIds: ["5c7cdb19344ca60005ee9614"]
        }
      ],
      overviewWidgets: [
        {
          type: "DynamicValueList",
          source: "utilizationMonthTopLocations",
          wpaCategoryId: "6423fc975d0a7a525fad3075",
          locationIds: [],
        },
        {
          type: "MonthlyUtilizationDistribtion",
          source: "utilizationDistributionMonth",
          wpaCategoryId: "6423fc895d0a7a525fad3074",
          locationIds: ["5c7cdb19344ca60005ee9614"],
        },
        {
          type: "VerticalBar",
          source: "peopleCountIntraday",
          customTagId: "660be2dfdc45295fc6a93a85",
          locationIds: ["5c7cdb19344ca60005ee9614", "5f9916ac309a0b000793e87a"]
        }
      ]
    }
  }
}

export const getWidgetProfile = (companyId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_WIDGET_PROFILE, { companyId }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/widget-profile`, await actions.getAxiosConfig())
    .then((response) => {
      if (response.data) {
        dispatch(actions.receiveData(response.data, types.GET_WIDGET_PROFILE, { companyId }));
      }
      else {
        const profile = widgetMockups(companyId);
        dispatch(actions.receiveData(profile, types.GET_WIDGET_PROFILE, { companyId }));
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WIDGET_PROFILE, { companyId }));
      }
    });
}

export const setWidgetProfile = (companyId, profile) => async (dispatch) => {
  dispatch(actions.requestData(types.SET_WIDGET_PROFILE, { companyId }));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/widget-profile`, profile, await actions.getAxiosConfig())
    .then((response) => {
      if (response.data) {
        dispatch(actions.receiveData(response.data, types.SET_WIDGET_PROFILE, { companyId, profile }));
      }
      else {
        const profile = widgetMockups(companyId);
        dispatch(actions.receiveData(profile, types.SET_WIDGET_PROFILE, { companyId }));
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SET_WIDGET_PROFILE, { companyId }));
      }
    });
}

export const getFullyUtilizedMinutes = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/fully-utilized-minutes/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getNotUtilizedMinutes = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/not-utilized-minutes/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getAtCapacityMinutes = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-minutes-at-capacity`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getNeverUtilized = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/never-utilized/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationIntraday = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/utilization-intra-day/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationDay = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: true,
    includeWeekends: true,
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationWeekday = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeWeekends: false
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationMonth = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationMonthlyDistribution = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));

  let startDate = moment.utc().subtract(2, "month").startOf("month");
  let endDate = moment.utc().subtract(1, "month").endOf("month");

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${martsUrl}companies/${companyId}/locations/${locationId}/occupancy/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId, startDate, endDate }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationTopLocations = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    limit: 5,
    sortOrder: "desc",
  };

  const url = `${martsUrl}companies/${companyId}/locations/${locationId}/usage/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationBottomLocations = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    limit: 5,
    sortOrder: "asc",
  };

  const url = `${martsUrl}companies/${companyId}/locations/${locationId}/usage/workplace-categories/${wpaCategoryId}`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getPeopleCountIntraday = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-intra-day-summed`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountDay = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    // dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: true,
    includeWeekends: true,
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountWeekday = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeWeekends: false
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountMonth = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));
      }
    });
}

// Simplified queryHash for location data
export const homeQueryHash = (search, startDate, endDate) => {
  
  let query = {
    search,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  };

  const queryHash = JSON.stringify(query);
  const hash = cyrb53(queryHash);
  return hash;
}

export const getLocationsOccupancyWeekdays = (locationIds) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS, { locationIds }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/location-occupancy-weekday`;

  axios.post(url, { locationIds }, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS, { locationIds }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS, { locationIds }));
      }
    });
}

export const getLocationsMaxPeopleWeekdays = (locationIds) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS, { locationIds }));

  const apiUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  const url = `${apiUrl}companies/${companyId}/location-peoplecount-weekday`;

  axios.post(url, { locationIds }, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS, { locationIds }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS, { locationIds }));
      }
    });
}

export const setWidgetSearch = (queryParams) => async (dispatch) => {
  dispatch({ type: types.SET_WIDGET_SEARCH, payload: queryParams });
}

export const getUptime = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {

  // Requires one id to be set
  if (wpaCategoryId === null && customTagId === null) {
    return;
  }

  dispatch(actions.requestData(types.GET_WIDGET_UPTIME, { locationId, wpaCategoryId, customTagId }));

  const apiUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ])
  };

  let url;
  if (wpaCategoryId) {
    url = `${apiUrl}companies/${companyId}/locations/${locationId}/uptime-summary/workplace-categories/${wpaCategoryId}`;
  }
  else if (customTagId) {
    url = `${apiUrl}companies/${companyId}/locations/${locationId}/people-count-uptime-summary/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WIDGET_UPTIME, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WIDGET_UPTIME, { locationId, wpaCategoryId, customTagId }));
      }
    });

  // TEMP Test
  let url2;
  if (wpaCategoryId) {
    url2 = `${apiUrl}companies/${companyId}/locations/${locationId}/uptime/workplace-categories/${wpaCategoryId}`;
  }
  else if (customTagId) {
    url2 = `${apiUrl}companies/${companyId}/locations/${locationId}/people-count-uptime/custom-tags/${customTagId}`;
  }

  axios.get(url2, await actions.getAxiosConfig(queryParams));
}