import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import Mapbox from "!mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import style from "./style.module.scss";
import { getMapBounds } from "../helpers";

let mapbox;
let mapboxDraw;
let shouldUpdateMap;
let mapHasLoaded;
let usesMazemap = false;

class InputPointMap extends Component {
  
  constructor(props) {
    // console.log("InputPointMap.constructor", props);
    super(props);
    shouldUpdateMap = false;
    mapHasLoaded = false;

    this.state = {
      showMap: false
    };
    
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onMapRender = this.onMapRender.bind(this);
    this.addPoint = this.addPoint.bind(this);
    this.loadConfig = this.loadConfig.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  loadConfig() {
    // console.log("InputPointMap.loadConfig", this.props);

    // Build config
    const config = {
      container: this.props.containerId || "mazemap-container",
      autoSetRTLTextPlugin: false
    };

    const nelng = get(this.props, "initialLocation.ne.lng", null);
    const nelat = get(this.props, "initialLocation.ne.lat", null);
    const swlng = get(this.props, "initialLocation.sw.lng", null);
    const swlat = get(this.props, "initialLocation.sw.lat", null);

    if (nelng && nelat && swlng && swlat) {
      // Center for initial location
      config.bounds = [[nelng, nelat], [swlng, swlat]];
      config.fitBoundsOptions = { padding: 0, bearing: this.props.bearing ?? 0, pitch: this.props.pitch ?? 0 };
    }
    else if (get(this.props.location, "geoJsonFeature.geometry", null)) {
      // Center for mapped location
      const llb = getMapBounds([this.props.location.geoJsonFeature]);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50, bearing: this.props.bearing ?? 0, pitch: this.props.pitch ?? 0  };
    }
    else if (get(this.props.map, "features[0].geometry", null)) {
      // Center for unmapped location
      const llb = getMapBounds(this.props.map.features);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50, bearing: this.props.bearing ?? 0, pitch: this.props.pitch ?? 0  };
    }
    else {
      // Skandinavia
      config.center = { lng: 17.7099499, lat: 64.55875 };
      config.zoom = 3.5;
    }

    // console.log("InputPointMap.loadConfig", config);

    if (this.props.location.floorLevel !== "" && !isEmpty(this.props.company.mazemapCampusTag)) {
      usesMazemap = true;
      config.campuses = this.props.company.mazemapCampusTag;
      config.zLevel = this.props.location.floorLevel;
      config.zLevelControl = false;

      let key;
      if (!isEmpty(this.props.company.mazemapApiKey)) {
        if (this.props.company.mazemapApiKey.length === 32) {
          key = `Bearer ${this.props.company.mazemapApiKey}`;
        }
        else {
          key = this.props.company.mazemapApiKey;
        }

        Mazemap.Config.setMazemapAuthToken(key); // eslint-disable-line no-undef
      }

      // console.log("InputPointMap.loadConfig new Mazemap.Map", config);

      mapbox = new Mazemap.Map(config); // eslint-disable-line no-undef
    }
    else {
      usesMazemap = false;

      Mapbox.accessToken = "pk.eyJ1IjoidG5zbWFydHgiLCJhIjoiY2s4aXJ6MTYxMDQycjNsbjYxZTA2ZmY5diJ9.6cYHg6iYWpNrtSuAjf-Eyg";
      config.style = "mapbox://styles/mapbox/streets-v11";
      config.attributionControl = false;

      mapbox = new Mapbox.Map(config).addControl(new Mapbox.AttributionControl({
        compact: true
      }));
    }

    const mazeMapOptions = {
      displayControlsDefault: false,
      defaultMode: this.props.mapMode ?? "simple_select",
      modes: {...MapboxDraw.modes },
      controls: {
        polygon: false,
        trash: false
      },
      userProperties: true
    };

    mapboxDraw = new MapboxDraw(mazeMapOptions); 
    mapbox.addControl(mapboxDraw, "top-left"); // Need to load map
    
    mapbox.on("draw.create", event => {
      const feature = get(event, "features[0]", null);
      if (feature && feature.geometry.type === "Point") {
        this.props.onPointChanged(feature);
      }
    });

    mapbox.on("draw.update", event => {
      const feature = get(event, "features[0]", null);
      if (feature && feature.geometry.type === "Point") {
        this.props.onPointChanged(feature);
      }
    });

    mapbox.on("moveend", () => {
      const currentBounds = mapbox.getBounds()
      const currentCenter = currentBounds.getCenter();
      const currentZoom = mapbox.getZoom();
      if (this.props.onCenterChanged) {
        this.props.onCenterChanged(currentCenter, currentZoom);
      }
      if (this.props.onBoundsChanged) {
        this.props.onBoundsChanged(currentBounds._ne, currentBounds._sw);
      }
    });

    mapbox.on("load", this.onMapLoad);

    mapbox.on("draw.render", this.onMapRender);
  }

  componentDidMount() {
    this.loadConfig();
  }

  componentWillUnmount() {
    // console.log("InputPointMap.componentWillUnmount", this.props);
    if (mapbox) {
      mapbox.off("load", this.onMapLoad);
      mapbox.off("draw.render", this.onMapRender);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // console.log("InputPointMap.componentWillUpdate", this.props, nextProps);
    if (this.props.location.floorMap === undefined && nextProps.location.floorMap !== undefined) {
      shouldUpdateMap = true;
    }

    // Remove point if it existed and was cleared
    if (this.props.point && !nextProps.point) {
      this.removePoint();
    }

    // Update mapMode in drawing lib
    if (this.props.mapMode !== nextProps.mapMode) {
      mapboxDraw.changeMode(nextProps.mapMode ?? "simple_select");
    }
  }
  
  componentDidUpdate() {
    if (shouldUpdateMap) {
      shouldUpdateMap = false;
      this.onMapLoad();
    }
  }

  onMapLoad() {
    mapHasLoaded = true;

    // Add geometry
    this.addPoint();
  }

  onMapRender() {
    if (mapHasLoaded && !this.state.showMap) {
      this.setState({ showMap: true });

      // Get initial bounds
      const currentBounds = mapbox.getBounds()
      const currentCenter = currentBounds.getCenter();
      const currentZoom = mapbox.getZoom();
      if (this.props.onCenterChanged) {
        this.props.onCenterChanged(currentCenter, currentZoom);
      }
      if (this.props.onBoundsChanged) {
        this.props.onBoundsChanged(currentBounds._ne, currentBounds._sw);
      }
    }
  }

  addPoint() {
    // Add point if it exists
    if (this.props.point) {
      mapboxDraw.add(this.props.point);
    }

    if (mapbox.getLayer("mm-campus-label") !== undefined) {
      mapbox.setLayoutProperty("mm-campus-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-building-label") !== undefined) {
      mapbox.setLayoutProperty("mm-building-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-poi-label") !== undefined) {
      mapbox.setLayoutProperty("mm-poi-label", "visibility", "none");
    }
  }

  removePoint() {
    mapboxDraw.deleteAll();
  }

  render() {
    // console.log("InputPointMap.render", this.props, this.state);
    return (
      <div className={style.mapContainer}>
        <div id={this.props.containerId || "mazemap-container"} className={style.map} style={{ opacity: this.state.showMap ? 1 : 0 }} />
      </div>
    );
  }
}

export default InputPointMap;
