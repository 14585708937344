import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Table from "../../../components/Table";
import Tag from "../../../components/Tag";
import { locationFilterTypes } from "../../../locationHelpers";
import * as selectedActions from "../../../actions/selected";
import styled from "styled-components";

class SourceView extends Component {

  constructor(props) {
    // console.log("Locations.constructor");
    super(props);
    this.state = {
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 100,
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
  }

  onRowClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original.id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original.id] !== undefined && this.props.selectedLocations[row.original.id]) {
      this.props.deselectLocation(row.original.id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAll(locations) {
    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location.id] !== undefined && this.props.selectedLocations[location.id]);
    if (allLocationsAreSelected) {
      this.props.deselectAllLocations();
    }
    else {
      this.props.selectLocations(locations);
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  onOffsetChange(offset) {
    this.setState({ offset });
  }

  getTag(id) {
    const tag = this.props.customTags.find(tag => tag.id === id);
    if (tag) {
      return <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />
    }
    return null;
  }

  render() {
    // console.log("SourceView.state", this.state);
    // console.log("SourceView.props", this.props);

    // Add properties to easier sort columns
    let locations = this.props.locationIds.map(locationId => this.props.flatHierarchy[locationId]);
    locations = locations.map(location => ({ ...location, typeName: get(locationFilterTypes.find(l => l.id === location.type), "name", location.type) }));
    locations = locations.map(location => {
      let tagString = "";
      if (location.customTags) {
        const norway = "nb-NO";
        tagString = location.customTags
          .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
          .filter(n => n)
          .map(tag => tag.name)
          .sort((a, b) => a.localeCompare(b, norway))
          .join(", ");
      }

      return {
        ...location,
        tagString
      };
    });

    if (this.state.sortBy && this.state.sortOrder) {
      locations = locations.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? a.name.localeCompare(b.name, norway) : b.name.localeCompare(a.name, norway);
        }
        else if (this.state.sortBy === "customTags") {
          // Sort by tagString with empty tags last
          if (a.tagString === "" && b.tagString !== "") {
            return this.state.sortOrder === "asc" ? 1 : -1;
          }
          else if (a.tagString !== "" && b.tagString === "") {
            return this.state.sortOrder === "asc" ? -1 : 1;
          }
          return this.state.sortOrder === "asc" ? a.tagString.localeCompare(b.tagString, norway) : b.tagString.localeCompare(a.tagString, norway);
        }
        else if (this.state.sortBy === "type") {
          return this.state.sortOrder === "asc" ? a.typeName.localeCompare(b.typeName) : b.typeName.localeCompare(a.typeName);
        }
        return false;
      });
    }

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location.id] !== undefined && this.props.selectedLocations[location.id]);

    // Limit locations based on offset and limit
    const pagedLocations = locations.slice(this.state.offset, this.state.offset + this.state.limit);

    const tableElement = (
      <Table
        data={pagedLocations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={locations.length}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={"No locations found"}
        columns={[
          {
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAll(locations)}
                />
                <span className="checkmark" />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original.id] !== undefined && this.props.selectedLocations[row.original.id])}
                  onChange={() => this.onToggle(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            // maxWidth: 700,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original.id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200,
            cell: ({ row }) => {
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type);
              const name = get(locationType, "name", row.original.type);
              return <span title={name}>{name}</span>
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
      />
    );

    let content = tableElement;

    return (
      <Container onClick={this.props.onClose}>
        <ScrollBox>
          <Block onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </CloseButton>
            <BlockContent>
              <BlockInnerTitle>Source overview</BlockInnerTitle>
              <BlockInnerDescription>All locations that has contributed data to the widget.</BlockInnerDescription>
              { content }
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    flatHierarchy: state.locations.flatHierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.customTags.list,
    selectedLocations: state.selected.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectAllLocations: selectedActions.deselectAllLocations,
    clearSelection: selectedActions.clearSelection,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceView);

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 5px;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  
  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  position: relative;
  min-height: 380px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 3;

  font-size: 20px;
  font-weight: 100;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
`;