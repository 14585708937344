import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  profile: null,
  profileDraft: null,
  currentQueryParams: null,
  data: {},
  loading: {}
};

export default function widgetsReducer(state = initialState, action) {

  let widgetKey = "";
  let dataState = {};
  let loadingState = {};

  switch (action.type) {

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_FULLY_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, fullyUtilizedMinutes: true };
      }
      
      if (action.fetchType === types.GET_NOT_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, notUtilizedMinutes: true };
      }

      if (action.fetchType === types.GET_NEVER_UTILIZED_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, neverUsedResourcesCount: true };
      }

      if (action.fetchType === types.GET_AT_CAPACITY_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, atCapacityMinutes: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationIntraday: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDay: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationWeekday: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonth: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDistributionMonth: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthTopLocations: true };
      }

      if (action.fetchType === types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthBottomLocations: true };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountIntraday: true };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountDay: true };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountWeekday: true };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountMonth: true };
      }

      if (action.fetchType === types.GET_WIDGET_UPTIME) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, uptime: true };
      }

      return { ...state, loading: { ...state.loading, [widgetKey]: loadingState }};
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_WIDGET_PROFILE) {
        return { ...state, profile: action.payload };
      }

      if (action.fetchType === types.SET_WIDGET_PROFILE) {
        return { ...state, profile: action.metadata.profile };
      }

      if (action.fetchType === types.GET_FULLY_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, fullyUtilizedMinutes: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, fullyUtilizedMinutes: false };
      }

      if (action.fetchType === types.GET_NOT_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, notUtilizedMinutes: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, notUtilizedMinutes: false };
      }

      if (action.fetchType === types.GET_NEVER_UTILIZED_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};

        // Add count to each neverUsedResourcesCount
        const neverUsedResourcesCount = action.payload.map((resource) => {
          return {
            ...resource,
            count: resource.unusedResources.length
          }
        });

        dataState = { ...dataState, neverUsedResourcesCount: neverUsedResourcesCount };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, neverUsedResourcesCount: false };
      }

      if (action.fetchType === types.GET_AT_CAPACITY_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, atCapacityMinutes: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, atCapacityMinutes: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationIntraday: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationIntraday: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationDay: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDay: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationWeekday: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationWeekday: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonth: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonth: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        
        const utilizationDistributionMonth = action.payload;

        utilizationDistributionMonth.forEach((data, index) => {

          // Find the total count of each distribution range
          let total = 0;
          data.distribution.forEach((distribution) => {
            total += distribution.count;
          });
          utilizationDistributionMonth[index].total = total;

          // Calculate high and low usage
          const highUsage = data.distribution.filter((data) => data.distribution >= 80);
          const lowUsage = data.distribution.filter((data) => data.distribution <= 20);
          const highUsageCount = highUsage.reduce((acc, data) => acc + data.count, 0);
          const lowUsageCount = lowUsage.reduce((acc, data) => acc + data.count, 0);
          utilizationDistributionMonth[index].highUsage = highUsageCount;
          utilizationDistributionMonth[index].lowUsage = lowUsageCount;

          // Combine 0-10% and 10-20% into 0-20% etc.
          const combinedDistributionArray = [];
          for (let i = 0; i < data.distribution.length - 1; i += 2) {
            const combinedData = {
              distribution: data.distribution[i].distribution,
              count: data.distribution[i].count + data.distribution[i + 1].count,
              total: total
            };
            combinedDistributionArray.push(combinedData);
          }
          utilizationDistributionMonth[index].combinedDistribution = combinedDistributionArray;

          // Calculate change between months
          if (index > 0) {
            const previousMonthData = utilizationDistributionMonth[index - 1];
            const previousHighUsage = previousMonthData.distribution.filter((data) => data.distribution >= 80);
            const previousLowUsage = previousMonthData.distribution.filter((data) => data.distribution <= 20);
            const previousHighUsageCount = previousHighUsage.reduce((acc, data) => acc + data.count, 0);
            const previousLowUsageCount = previousLowUsage.reduce((acc, data) => acc + data.count, 0);
            utilizationDistributionMonth[index].highUsageChange = highUsageCount - previousHighUsageCount;
            utilizationDistributionMonth[index].lowUsageChange = lowUsageCount - previousLowUsageCount;

            // Monthly change between distribution ranges
            for (let i = 0; i < data.distribution.length; i++) {
              const change = data.distribution[i].count - previousMonthData.distribution[i].count;
              utilizationDistributionMonth[index].distribution[i].change = change;
            }

            // Monthly change between combined distribution ranges
            for (let i = 0; i < combinedDistributionArray.length; i++) {
              const change = combinedDistributionArray[i].count - previousMonthData.combinedDistribution[i].count;
              utilizationDistributionMonth[index].combinedDistribution[i].change = change;
            }
          }
        });

        dataState = { ...dataState, utilizationDistributionMonth };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDistributionMonth: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonthTopLocations: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthTopLocations: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonthBottomLocations: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthBottomLocations: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountIntraday: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountIntraday: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountDay: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountDay: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountWeekday: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountWeekday: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountMonth: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountMonth: false };
      }

      if (action.fetchType === types.GET_WIDGET_UPTIME) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, uptime: action.payload };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, uptime: false };
      }

      return { ...state, data: { ...state.data, [widgetKey]: dataState }, loading: { ...state.loading, [widgetKey]: loadingState }};
    }

    case types.RECV_ERROR: {

      if (action.fetchType === types.GET_FULLY_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, fullyUtilizedMinutes: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, fullyUtilizedMinutes: false };
      }

      if (action.fetchType === types.GET_NOT_UTILIZED_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, notUtilizedMinutes: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, notUtilizedMinutes: false };
      }

      if (action.fetchType === types.GET_NEVER_UTILIZED_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, neverUsedResourcesCount: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, neverUsedResourcesCount: false };
      }

      if (action.fetchType === types.GET_AT_CAPACITY_MINUTES_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, atCapacityMinutes: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, atCapacityMinutes: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationIntraday: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationIntraday: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationDay: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDay: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationWeekday: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationWeekday: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonth: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonth: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationDistributionMonth: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationDistributionMonth: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonthTopLocations: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthTopLocations: false };
      }

      if (action.fetchType === types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, utilizationMonthBottomLocations: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, utilizationMonthBottomLocations: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountIntraday: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountIntraday: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_DAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountDay: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountDay: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountWeekday: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountWeekday: false };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_MONTH_WIDGET) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, peopleCountMonth: [] };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, peopleCountMonth: false };
      }

      if (action.fetchType === types.GET_WIDGET_UPTIME) {
        widgetKey = `${action.metadata.locationId}-${action.metadata.wpaCategoryId ?? null}-${action.metadata.customTagId ?? null}`;
        dataState = state.data[widgetKey] || {};
        dataState = { ...dataState, uptime: null };
        loadingState = state.loading[widgetKey] || {};
        loadingState = { ...loadingState, uptime: false };
      }

      return { ...state, data: { ...state.data, [widgetKey]: dataState }, loading: { ...state.loading, [widgetKey]: loadingState }};
    }

    case types.SET_WIDGET_SEARCH: {
      return {...state, currentQueryParams: action.payload };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.UPDATE_WIDGET_PROFILE_DRAFT: {
      return {...state, profileDraft: action.payload };
    }

    case types.CLEAR_WIDGET_PROFILE_DRAFT: {
      return {...state, profileDraft: null };
    }

    default: {
      return state;
    }
  }
}