import React, { useState, useEffect } from "react";
import { WidgetValueType } from "./types";
import styled from "styled-components";

interface TextValueProps {
  targetValue: number; // Target percentage value
  valueType: WidgetValueType;
  duration: number; // Animation duration in milliseconds
}

const TextValue: React.FC<TextValueProps> = ({ targetValue, valueType, duration }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [currentHours, setCurrentHours] = useState(0);
  const [currentMinutes, setCurrentMinutes] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const animate = () => {

      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress does not exceed 1

      // Use an easing function to match the CSS transition flow, if necessary
      const easeInOut = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const easedProgress = easeInOut(progress);

      if (valueType === WidgetValueType.Minutes) {

        const targetHours = Math.floor(targetValue / 60);
        const targetMinutes = Math.floor(targetValue % 60);

        const newHours = Math.floor(easedProgress * (targetHours - currentHours) + currentHours);
        const newMinutes = Math.floor(easedProgress * (targetMinutes - currentMinutes) + currentMinutes);
        setCurrentHours(newHours);
        setCurrentMinutes(newMinutes);

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
        else {
          // Ensure it ends precisely at the target value
          setCurrentHours(targetHours);
          setCurrentMinutes(targetMinutes);
        }
      }
      else {

        const newValue = easedProgress * (targetValue - currentValue) + currentValue;
        setCurrentValue(newValue);
  
        if (progress < 1) {
          requestAnimationFrame(animate);
        } 
        else {
          // Ensure it ends precisely at the target value
          setCurrentValue(targetValue); 
        }
      }
    };

    requestAnimationFrame(animate);
  }, [targetValue, duration]);

  const result = isNaN(currentValue) ? "-" : Math.floor(currentValue);

  const valueParts = [];

  if (!isNaN(currentValue) && valueType === WidgetValueType.Minutes) {

    valueParts.push(
      <InnerBox key="hours">
        <LeftSide>
          { currentHours }
        </LeftSide>
        <RightSide>
          { "hours" }
        </RightSide>
      </InnerBox>
    );

    valueParts.push(
      <InnerBox key="minutes">
        <LeftSide>
          { currentMinutes }
        </LeftSide>
        <RightSide>
          { "minutes" }
        </RightSide>
      </InnerBox>
    );

  }
  else {

    const valueElement = (
      <InnerBox>
        <LeftSide>
          { result }
        </LeftSide>
      </InnerBox>
    );

    valueParts.push(valueElement);
  }

  return (
    <OuterBox>
      { valueParts }
    </OuterBox>
  );
};

export default TextValue;

const OuterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 14px;
`;

const InnerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const LeftSide = styled.div`
  font-size: 35px;
`;

const RightSide = styled.div`
  display: flex;
  font-size: 20px;
  color: #555;
`;