
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { getCompanyId } from "../../helpers";
import * as locationActions from "../../actions/locations";
import styled from "styled-components";

class NavSearchBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resultsVisible: false,
      highlightedIndex: -1
    };

    this.inputRef = React.createRef();

    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputChanged = this.onInputChanged.bind(this);
    this.onInputClear = this.onInputClear.bind(this);
    this.onViewBigSearchClicked = this.onViewBigSearchClicked.bind(this);
    this.onResultClick = this.onResultClick.bind(this);
    this.onMouseEnterResult = this.onMouseEnterResult.bind(this);
    this.onMouseLeaveResults = this.onMouseLeaveResults.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onUpKeyPressed = this.onUpKeyPressed.bind(this);
    this.onDownKeyPressed = this.onDownKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
    document.addEventListener('mouseup', this.handleDocumentMouseUp);
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
  }
  
  handleDocumentMouseDown = (event) => {
    // Track if the click is inside the dropdown or the input
    this.isClickInside = this.node && this.node.contains(event.target);
  };
  
  handleDocumentMouseUp = () => {
    if (!this.isClickInside) {
      this.setState({ resultsVisible: false });
    }
  };

  onInputFocus() {
    this.setState({ resultsVisible: true });
  }

  onInputChanged(searchText) {
    this.props.updateQuickSearchText(searchText);
  }

  onInputClear() {
    this.props.updateQuickSearchText("");
    this.setState({ resultsVisible: false });
  }

  onViewBigSearchClicked() {
    this.props.setHomeLocationSearch({ search: this.props.searchText });
    this.props.history.push("/home/locations?search=" + this.props.searchText);
    this.setState({ resultsVisible: false });
  }

  onResultClick(location) {
    const companyId = getCompanyId();
    if (companyId) {
      this.props.history.push(`/companies/${companyId}/locations/${location.id}`);
    }
    this.setState({ resultsVisible: false });
  }

  onMouseEnterResult(index) {
    this.setState({ highlightedIndex: index });
  }

  onMouseLeaveResults() {
    this.setState({ highlightedIndex: -1 });
  }

  handleKeyDown(event) {
    switch (event.key) {
      case "ArrowUp":
        this.onUpKeyPressed();
        event.preventDefault(); // Prevent the default action to avoid scrolling the browser window
        break;
      case "ArrowDown":
        this.onDownKeyPressed();
        event.preventDefault(); // Prevent the default action to avoid scrolling the browser window
        break;
      case "Enter":
        if (this.state.highlightedIndex >= 0) {
          if (this.state.highlightedIndex < this.props.locations.length) {
            this.onResultClick(this.props.locations[this.state.highlightedIndex]);
          }
          else {
            this.onViewBigSearchClicked();
          }
          this.inputRef.current.blur();
        }
        break;
      default:
        break;
    }
  }

  onUpKeyPressed() {
    const newIndex = this.state.highlightedIndex - 1;
    this.setState({
      highlightedIndex: newIndex >= 0 ? newIndex : this.props.locations.length // Loop around to the last item
    });
  }

  onDownKeyPressed() {
    const newIndex = this.state.highlightedIndex + 1;
    this.setState({
      highlightedIndex: newIndex <= this.props.locations.length ? newIndex : 0 // Loop around to the first item
    });
  }

  render() {

    const showResults = this.state.resultsVisible && !isEmpty(this.props.searchText);

    let searchResultRows = [];
    if (this.props.locations && this.props.locations.length > 0) {

      // Limit the number of results to 5
      const limitedLocations = this.props.locations.slice(0, 5);

      searchResultRows = limitedLocations.map((result, index) => {
        let locationId = result._id || result.id;
        let breadcrumbs = this.props.locationBreadcrumbs[locationId].map(breadcrumb => breadcrumb.name);
        breadcrumbs.pop();
        breadcrumbs = breadcrumbs.join(", ");

        return (
          <SearchResult key={index} $highlighted={this.state.highlightedIndex === index} onClick={() => this.onResultClick(result)} onMouseEnter={() => this.onMouseEnterResult(index)}>
            <Row>
              <div title={result.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{result.name}</div>
              <div title={breadcrumbs} style={{ color: "grey", marginTop: "2px", textOverflow: "ellipsis", overflow: "hidden" }}>{breadcrumbs || "-"}</div>
            </Row>
          </SearchResult>
        );
      });

      // Add a "see all results" link
      searchResultRows.push(
        <SearchResult key={"see-all"} $highlighted={this.state.highlightedIndex === this.props.locations.length} onClick={this.onViewBigSearchClicked} onMouseEnter={() => this.onMouseEnterResult(this.props.locations.length)}>
          <Row>
            <InfoRowContent>See all results for &quot;{this.props.searchText}&quot;</InfoRowContent>
          </Row>
        </SearchResult>
      );
    }
    else {
      searchResultRows = (
        <SearchResult key={"no-results"} disabled>
          <Row>
            <InfoRowContent>No results found</InfoRowContent>
          </Row>
        </SearchResult>
      );
    }

    return (
      <SearchContainer ref={node => this.node = node}>
        <Box $showResults={showResults}>
          <InnerBox>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} color={"#999"} />
            </SearchIcon>
            <Input
              ref={this.inputRef}
              name="search"
              placeholder={"Search"}
              onChange={(event) => this.onInputChanged(event.target.value)}
              onFocus={this.onInputFocus}
              value={this.props.searchText}
              autoComplete="off"
              onKeyDown={this.handleKeyDown}
            />
            { // button to clear the search field
              !isEmpty(this.props.searchText) && (
                <ClearButton onClick={() => this.onInputClear()}>
                  <FontAwesomeIcon icon={faXmark} color={"#999"} />
                </ClearButton>
              )
            }
          </InnerBox>
        </Box>
        <SearchResults $showResults={showResults} onMouseLeave={this.onMouseLeaveResults}>
          {searchResultRows}
        </SearchResults>
      </SearchContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchText: state.locations.quickSearchText,
    locations: state.locations.quickSearchLocations,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    isLoading: state.loading.locationSearch
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateQuickSearchText: locationActions.updateQuickSearchText,
    setHomeLocationSearch: locationActions.setHomeLocationSearch
  },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavSearchBox));


const SearchContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;

  *:focus {
    outline: none;
  }
`;

const Box = styled.div`
  display: block;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  border-width: 0;
  outline-color: rgb(221, 221, 221);
  outline-width: 1px;
  outline-style: solid;
  border-radius: ${props => props.$showResults ? "5px 5px 0 0" : "5px"};
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  width: 300px;
  z-index: 2;
`;

const InnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SearchIcon = styled.div`
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const ClearButton = styled.div`
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
`;

const Input = styled.input`
  background-color: white !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #222;
  font-size: 17px;
  font-weight: 400;
  height: 40px;
  padding: 0;
  flex-grow: 1;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;

const SearchResults = styled.div`
  display: ${props => props.$showResults ? "block" : "none"};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background-color: white;

  border-width: 0;
  outline-color: rgb(221, 221, 221);
  outline-width: 1px;
  outline-style: solid;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  box-sizing: border-box;
`;

const SearchResult = styled.li`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 15px;
  cursor: ${props => !props.disabled ? "pointer" : "default"};

  background-color: ${props => !props.disabled && props.$highlighted && "#f5f5f5"};

  border-top: 1px solid rgb(221, 221, 221);
  box-sizing: border-box;

  &:first-child {
    border-top: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InfoRowContent = styled.div`
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;