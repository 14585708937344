import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { populateAncestors, flattenHierarchy } from "../locationHelpers";
import { sortSensorTypes, sensorTypeIgnoreList } from "../dashboardHelpers";
import moment from "moment";

const initialState = {
  data: {
    count: 0,
    locations: [],
    limit: 20
  },
  hierarchy: [],
  flatHierarchy: {},
  breadcrumbs: {},
  uploadResponse: {},
  uploadErrors: [],
  correlationIds: {},

  // Quick search query data
  quickSearchText: "",
  quickSearchLocations: [],

  // Home locations query data
  currentQueryParams: null,
  homeSearchText: null,
  homeSearchLocations: [],
  customTags: null,
  locationsOccupancyWeekdays: {},
  locationsMaxPeopleWeekdays: {}
};

export default function locationsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_LOCATION) {
        // Clear locations list when changing location viewing
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_ROOT_LOCATION) {
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_LOCATIONS) {
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        // Clear locations list when changing location viewing
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        return { ...state, uploadResponse: {}, uploadErrors: [] };
      }

      if (action.fetchType === types.SEARCH_LOCATIONS) {
        let newState = { ...state };
        newState.correlationIds = { ...state.correlationIds, [types.SEARCH_LOCATIONS]: action.metadata.correlationId };
        return newState;
      }

      if (action.fetchType === types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS) {

        // Traverse all locations to mark them as loading
        const locationsOccupancyWeekdays = { ...state.locationsOccupancyWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsOccupancyWeekdays[locationId] = { status: "loading" };
        });

        return { ...state, locationsOccupancyWeekdays };
      }

      if (action.fetchType === types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS) {

        // Traverse all locations to mark them as loading
        const locationsMaxPeopleWeekdays = { ...state.locationsMaxPeopleWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsMaxPeopleWeekdays[locationId] = { status: "loading" };
        });

        return { ...state, locationsMaxPeopleWeekdays };
      }

      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_LOCATIONS) {
        return { ...state, data: action.payload };
      }

      if (action.fetchType === types.DELETE_LOCATIONS) {
        toast.success("Location(s) deleted");
        return state;
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {

        // Add breadcrumbs to locations
        let breadcrumbs = {};
        if (action.payload.length > 0) {
          populateAncestors(action.payload, [], breadcrumbs);
        }

        // Flatten hierarchy
        let flatHierarchy = {};
        if (action.payload.length > 0) {
          flattenHierarchy(action.payload, [], flatHierarchy);
        }

        return { ...state, hierarchy: action.payload, breadcrumbs, flatHierarchy };
      }

      if (action.fetchType === types.GET_CUSTOM_TAGS) {
        return { ...state, customTags: action.payload };
      }

      if (action.fetchType === types.ADD_LOCATION) {
        toast.success("Location(s) added");
        return state;
      }

      if (action.fetchType === types.MOVE_LOCATIONS) {
        toast.success("Location(s) moved");
        return state;
      }

      if (action.fetchType === types.MAP_FEATURE_TO_LOCATION) {
        toast.success("Location mapped");
        return state;
      }

      if (action.fetchType === types.REMOVE_FEATURES_FROM_LOCATIONS) {
        toast.success("Location features removed");
        return state;
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        if (isEmpty(action.payload.csvHash)) {
          return { ...state, uploadResponse: {}, uploadErrors: [] };
        }

        return { ...state, uploadResponse: action.payload, uploadErrors: [] };
      }

      // if (action.fetchType === types.SEARCH_LOCATIONS) {
      //   if (action.metadata.correlationId !== state.correlationIds[types.SEARCH_LOCATIONS]) {
      //     return state;
      //   }

      //   return { ...state, quickSearchLocations: get(action, "payload.locations", []) };
      // }

      if (action.fetchType === types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS) {

        // Traverse all locations to mark them as loaded
        const locationsOccupancyWeekdays = { ...state.locationsOccupancyWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsOccupancyWeekdays[locationId] = { status: "loaded" };
        });

        // Traverse all fetched data and add the weekdays data to the state
        action.payload.forEach((data) => {
          const locationId = data.locationId;
          locationsOccupancyWeekdays[locationId] = { ...locationsOccupancyWeekdays[locationId], weekdays: data.weekdays };
        });

        return { ...state, locationsOccupancyWeekdays };
      }

      if (action.fetchType === types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS) {

        // Traverse all locations to mark them as loaded
        const locationsMaxPeopleWeekdays = { ...state.locationsMaxPeopleWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsMaxPeopleWeekdays[locationId] = { status: "loaded" };
        });

        // Traverse all fetched data and add the weekdays data to the state
        action.payload.forEach((data) => {
          const locationId = data.locationId;
          locationsMaxPeopleWeekdays[locationId] = { ...locationsMaxPeopleWeekdays[locationId], weekdays: data.weekdays };
        });

        return { ...state, locationsMaxPeopleWeekdays };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_LOCATION) {
        return state;
      }

      if (action.fetchType === types.GET_ROOT_LOCATION) {
        toast.error(`${statusCode}: Could not get root location`);
        return state;
      }

      if (action.fetchType === types.GET_LOCATIONS) {
        toast.error(`${statusCode}: Could not get locations`);
        return state;
      }

      if (action.fetchType === types.DELETE_LOCATIONS) {
        toast.error(`${statusCode}: Could not delete location(s)`);
        return state;
      }

      if (action.fetchType === types.ADD_LOCATION) {
        toast.error(`${statusCode}: Could not add location`);
        return state;
      }

      if (action.fetchType === types.MOVE_LOCATIONS) {
        toast.error(`${statusCode}: Could not move location(s)`);
        return state;
      }

      if (action.fetchType === types.MAP_FEATURE_TO_LOCATION) {
        toast.error(`${statusCode}: Could not connect location`);
        return state;
      }

      if (action.fetchType === types.REMOVE_FEATURES_FROM_LOCATIONS) {
        toast.error(`${statusCode}: Could not remove feature(s)`);
        return state;
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        const errors = get(action, "payload.response.data.errors", []);
        toast.error(`${statusCode}: Could not upload`);
        return { ...state, uploadResponse: {}, uploadErrors: errors };
      }

      if (action.fetchType === types.GET_LOCATION_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }

      if (action.fetchType === types.GET_RESOURCE_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }

      if (action.fetchType === types.SEARCH_LOCATIONS) {
        return { ...state, quickSearchLocations: [] };
      }

      if (action.fetchType === types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS) {

        // Traverse all locations to mark them as loaded
        const locationsOccupancyWeekdays = { ...state.locationsOccupancyWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsOccupancyWeekdays[locationId] = { status: "error" };
        });

        return { ...state, locationsOccupancyWeekdays };
      }

      if (action.fetchType === types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS) {

        // Traverse all locations to mark them as loaded
        const locationsMaxPeopleWeekdays = { ...state.locationsMaxPeopleWeekdays };
        action.metadata.locationIds.forEach((locationId) => {
          locationsMaxPeopleWeekdays[locationId] = { status: "error" };
        });

        return { ...state, locationsMaxPeopleWeekdays };
      }

      return state;
    }

    case types.CLEAR_DATA: {
      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        return { ...state, uploadResponse: {}, uploadErrors: [] };
      }

      return initialState;
    }

    case types.UPDATE_QUICK_SEARCH_TEXT: {

      // Filter locations based on search text
      const searchText = action.payload.toLowerCase();

      // Check if each word (words in quotes are treated as one word) in the search text is in the location name or location type
      const searchWords = searchText.match(/"[^"]+"|\S+/g) || [];
      if (searchWords.length === 0) {
        return { ...state, quickSearchText: action.payload, quickSearchLocations: [] };
      }

      const locations = Object.values(state.flatHierarchy);

      const quickSearchLocations = locations.filter((location) => {
        const locationName = location.name.toLowerCase();
        const locationType = location.type.toLowerCase();

        // Check if all words are in the location name or location type
        return searchWords.every((word) => {
          word = word.replace(/"/g, "");
          return locationName.includes(word) || locationType.includes(word);
        });
      });

      // Sort locations by name
      quickSearchLocations.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      // Only return 5
      quickSearchLocations.splice(5);

      return { ...state, quickSearchText: action.payload, quickSearchLocations };
    }

    case types.SET_HOME_LOCATION_SEARCH: {
      return {...state, currentQueryParams: action.payload };
    }

    case types.HOME_LOCATIONS_SEARCH: {

      const locations = Object.values(state.flatHierarchy);
      let searchResult = [];

      // Filter locations based on search text
      const searchText = action.payload.toLowerCase();

      // Check if each word (words in quotes are treated as one word) in the search text is in the location name or location type
      const searchWords = searchText.match(/"[^"]+"|\S+/g) || [];
      if (searchWords.length === 0) {
        searchResult = locations;
      }
      else {
        searchResult = locations.filter((location) => {
          const locationName = location.name.toLowerCase();
          const locationType = location.type.toLowerCase();
          const locationCustomTags = location.customTagIds?.map((customTagId) => state.customTags?.find(customTag => customTag.id === customTagId)?.name.toLowerCase() ?? "") || [];

          // Check if all words are in the location name or location type
          return searchWords.every((word) => {
            word = word.replace(/"/g, "");
            const inName = locationName.includes(word);
            const inType = locationType.includes(word);
            const inAnyCustomTag = locationCustomTags.some((tag) => tag.includes(word));
            return inName || inType || inAnyCustomTag;
          });
        });
      }
  
      // Sort locations by name
      searchResult.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      // Create a complete list of sensor types for all locations in search results
      let sensorTypes = [];
      searchResult.some(location => {
        sensorTypes = [...new Set([...sensorTypes, ...state.flatHierarchy[location.id]?.recordedProperties ?? []])]; 
      });

      // Replace footfallIn/Out with peopleCount
      if (sensorTypes.includes("footfallCountIn") && sensorTypes.includes("footfallCountOut")) {
        sensorTypes = sensorTypes.filter(type => !["footfallCountIn", "footfallCountOut", "egress", "ingress", "peopleCount"].includes(type));
        sensorTypes = [...sensorTypes, "peopleCount"];
      }

      // Replace motionCount with occupiedMinutes
      if (sensorTypes.includes("motionCount")) {
        sensorTypes = sensorTypes.filter(type => !["occupiedMinutes", "motionCount"].includes(type));
        sensorTypes = [...sensorTypes, "occupiedMinutes"];
      }

      // Ignore spesific sensor types
      sensorTypes = sensorTypes.filter(sensorType => !sensorTypeIgnoreList.includes(sensorType));

      // Sort sensors by type
      sensorTypes = sortSensorTypes(sensorTypes);
      
      return {...state, homeSearchText: searchText, homeSearchLocations: searchResult };
    }

    default: {
      return state;
    }
  }
}
